import React, { useState } from 'react'

import Button from 'react-bootstrap/Button'
import uuidv4 from 'uuid/v4'

import { Variant } from 'src/constants'

const DatadogSessionTracker: React.FC = () => {
  // generate a uuid for this moment in the session
  const moment_uuid = 'TAG-' + uuidv4().split('-')[4]
  const [copied, setCopied] = useState(false)

  return (
    <div>
      To help us diagnose the issue, please click the button to copy this
      session ID. Give this ID to an engineer when you file a ticket.
      <div className="d-flex flex-column align-items-center">
        <Button
          onClick={() => {
            navigator.clipboard.writeText(moment_uuid)
            setCopied(true)
          }}
          title="Click to Copy"
          variant={Variant.WARNING}
        >
          <pre className="mb-0">{moment_uuid}</pre>
        </Button>
        {copied && <p className="text-success">Copied!</p>}
      </div>
    </div>
  )
}

export default DatadogSessionTracker
