import { uniqueId } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import sumBy from 'lodash/sumBy'

import { RequestedProduct } from 'src/components/AddProductForm'
import { DRAFT_ORDER_ID, LOCAL_CREATED_ORDER_ID_PREFIX } from 'src/constants'
import { FeatureFlagName } from 'src/models/config'
import { OrderPreview, Priority, Status } from 'src/models/order'
import { DailySummaryOrder } from 'src/models/package'
import { CreditCheckStatus } from 'src/models/sites'
import GlobalStore from 'src/stores/GlobalStore'
import HealthFacilityStore from 'src/stores/HealthFacilityStore'
import { isCurrentTimeWithin, isTomorrowOrLater } from 'src/utils/datetime'

export const isUpcomingScheduledOrder = (order: OrderPreview): boolean => {
  if (order.priority !== Priority.SCHEDULED || !order.timeScheduled) {
    return false
  }

  return isTomorrowOrLater(order.timeScheduled)
}

export const getPodData = (
  order: DailySummaryOrder
): {
  numSigned: number
  numDelivered: number
  totalSignatureRequired: number
} => {
  const pods = order.pods.filter((pod) => pod.requiresSignature)
  return {
    numSigned: sumBy(pods, (pod) => (!isEmpty(pod.timeSigned) ? 1 : 0)),
    numDelivered: sumBy(pods, (pod) => (!isEmpty(pod.timeDelivered) ? 1 : 0)),
    totalSignatureRequired: pods.length,
  }
}

export const getLocalOrderId = (): string => {
  return uniqueId(LOCAL_CREATED_ORDER_ID_PREFIX + ' ')
}

// Returns true for draft orders and unsynced local orders
export const isUnsyncedOrderId = (orderId?: string): boolean => {
  return (
    !!orderId &&
    (orderId.startsWith(LOCAL_CREATED_ORDER_ID_PREFIX) ||
      orderId === DRAFT_ORDER_ID)
  )
}

// Additional logic to determine final order status - credit check, emergency order confirmation
export const computeOrderSubstatus = (order: OrderPreview): Status => {
  if (Status.CANCELED === order.status) {
    return order.status
  }

  // Check if awaiting or failed credit check
  if (GlobalStore.getFeatureFlag(FeatureFlagName.CREDIT_CHECK_ENABLED)) {
    const facility = HealthFacilityStore.facilitiesById[order.recipientRoleId!]
    if (facility) {
      if (!facility.creditCheckStatus) {
        return Status.AWAITING_CREDIT_CHECK
      } else if (
        facility.creditCheckStatus === CreditCheckStatus.FAIL &&
        !order.creditCheckOverrideReason &&
        !order.timeCustomerNotifiedCreditCheckFailed
      ) {
        return Status.FAILED_CREDIT_CHECK
      }
    }
  }

  // Check if awaiting emergency order confirmation
  if (
    GlobalStore.getFeatureFlag(
      FeatureFlagName.EMERGENCY_ORDER_CONFIRMATION_ENABLED
    )
  ) {
    const startTime = GlobalStore.getFeatureFlag(
      FeatureFlagName.EMERGENCY_ORDER_CONFIRMATION_START
    )
    const endTime = GlobalStore.getFeatureFlag(
      FeatureFlagName.EMERGENCY_ORDER_CONFIRMATION_END
    )
    if (!startTime || !endTime) {
      throw new Error(
        'Emergency order confirmation start and/or end times not configured.'
      )
    }
    const isWithinWindow = isCurrentTimeWithin(
      startTime as string,
      endTime as string
    )

    // if order is emergency, and delivered, but no time_pod_signed
    if (
      isWithinWindow &&
      order.priority == Priority.EMERGENCY &&
      order.status === Status.DELIVERED &&
      !order.timePodSigned
    ) {
      return Status.NEEDS_RECEIPT_CONFIRMATION
    }
  }

  return order.status
}

export const pharamacistRequiredForRequestedProduct = (
  category: string,
  priority: Priority
): boolean => {
  if (priority === Priority.EMERGENCY || !category) {
    return false
  } else {
    const categoryLowerCased = category.toLowerCase()
    const isControlledSubstance =
      categoryLowerCased.match(/controlled substance/i)
    return isControlledSubstance !== null
  }
}

export const pharmacistRequiredForOrder = (
  requests: RequestedProduct[]
): boolean => {
  // Pharmacist information is required when the product is a controlled substance and the priority is replenishment or scheduled order
  const includesControlledSubstance = requests.some((product) => {
    return pharamacistRequiredForRequestedProduct(
      product.category,
      product.priority
    )
  })
  return includesControlledSubstance
}
