import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import pickBy from 'lodash/pickBy'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'

import {
  Notification as NotificationModel,
  NotificationType,
} from 'src/models/notification'
import * as NotificationService from 'src/services/NotificationService'
import PollService from 'src/services/PollService'
import CustomerServiceStore from 'src/stores/CustomerServiceStore'
import ViewStore, { ViewId } from 'src/stores/ViewStore'

import GlobalStore from './GlobalStore'

const NOTIFICATIONS_KEY = 'notifications'

const FIVE_MINUTES = 5 * 60 * 1000

class NotificationsStore {
  @observable notifications: Record<string, NotificationModel> = {}
  @observable desktopNotificationsEnabled = false
  @observable activeAudioNotification?: NotificationModel = undefined
  pollService: PollService<string, NotificationModel[]> = new PollService()

  // A mapping from notification ID to timestamp of read notifications.
  readNotifications: Record<string, string> = this.getReadNotifications()

  // A mapping from notification ID to audio playing poll service
  audioNotificationIntervals: Record<string, number> = {}

  constructor() {
    makeObservable(this)
  }

  @computed get notificationsByEntity(): Record<string, NotificationModel[]> {
    return groupBy(this.notifications, (notification) => notification.entityId)
  }

  @computed get sortedNotifications(): NotificationModel[] {
    return orderBy(this.notifications, ['timestamp'], ['desc'])
  }

  @action.bound startPollingNotifications(pollPeriod = 5000): void {
    this.pollService.startPolling(
      () => this.updateNotifications(),
      NOTIFICATIONS_KEY,
      pollPeriod
    )
  }

  @action.bound stopPollingNotifications(): void {
    this.pollService.stopPolling(NOTIFICATIONS_KEY)
    this.notifications = {}
  }

  @action.bound async updateNotifications(): Promise<NotificationModel[]> {
    const notifications = await NotificationService.getNotifications()
    runInAction(() => {
      const notificationIds = new Set<string>()
      notifications.forEach((notification) => {
        this.notifications[notification.id] = notification
        notificationIds.add(notification.id)

        // If the notification is for a new order from CB, set an audio notification at a 5 minute interval
        if (
          notification.notificationType === NotificationType.NEW_ORDER &&
          this.audioNotificationIntervals[notification.id] === undefined
        ) {
          this.readNotificationAudio(notification)
          this.audioNotificationIntervals[notification.id] = window.setInterval(
            () => {
              this.readNotificationAudio(notification)
            },
            FIVE_MINUTES
          )
        }

        if (
          this.readNotifications[notification.id] === notification.timestamp
        ) {
          return
        }

        this.sendNotification(notification)
        this.readNotifications[notification.id] = notification.timestamp

        // Save notifications to session storage to avoid duplicate notifications.
        sessionStorage.setItem(
          NOTIFICATIONS_KEY,
          JSON.stringify(this.readNotifications)
        )
      })

      // Delete any notifications that were removed in the poll.
      this.notifications = pickBy(
        this.notifications,
        (_notification, id: string) => notificationIds.has(id)
      )
    })
    return notifications
  }

  @action.bound sendNotification(notification: NotificationModel): void {
    if (this.desktopNotificationsEnabled) {
      new Notification(notification.message)
    } else {
      toast.info(notification.message, {
        closeOnClick: true,
        onClick: () => this.navigate(notification),
      })
    }
  }

  /** Reads from sessionStorage to avoid duplicate messages. */
  getReadNotifications(): Record<string, string> {
    return JSON.parse(sessionStorage.getItem(NOTIFICATIONS_KEY) || '{}')
  }

  navigate(notification: NotificationModel): void {
    if (notification.metadata?.orderId) {
      ViewStore.showView(
        ViewId.CUSTOMER_SERVICE,
        { orderId: notification.metadata.orderId },
        {
          scripts: '1',
          packageId: notification.metadata.packageId ?? '',
        }
      )
      // Update the selected order in the CustomerServiceStore.
      CustomerServiceStore.selectOrder(notification.metadata.orderId)
    }
  }

  has_new_order_notification(): boolean {
    const ids = Object.keys(this.notifications)
    return (
      ids.length > 0 &&
      ids.some(
        (notification_id) =>
          this.notifications[notification_id].notificationType ===
            NotificationType.TIME_BEGIN_PACKING ||
          this.notifications[notification_id].notificationType ===
            NotificationType.NEW_ORDER
      )
    )
  }

  readNotificationAudio(notification: NotificationModel): void {
    // Remove the notification from the audio list if it's no longer valid
    if (!this.notifications[notification.id]) {
      clearInterval(this.audioNotificationIntervals[notification.id])
      delete this.audioNotificationIntervals[notification.id]
      return
    }

    if (GlobalStore.orderAudioEnabled) {
      this.activeAudioNotification = notification
      const synth = window.speechSynthesis
      const utterance = new SpeechSynthesisUtterance(notification.message)
      synth.speak(utterance)
      setTimeout(() => {
        this.activeAudioNotification = undefined
      }, 10000)
    }
  }

  createNotification(
    notification: Omit<NotificationModel, 'id'>
  ): Promise<boolean> {
    return NotificationService.createNotification(notification)
  }
}

export default new NotificationsStore()
