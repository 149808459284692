import { PrinterLabel } from 'src/models/printer_label'
import {
  Shipment,
  ShipmentPreview,
  ShipmentPreviewUnits,
} from 'src/models/shipment'
import { FulfillmentOperator } from 'src/models/users'
import {
  transformShipment,
  transformShipmentPreview,
  transformShipmentPreviewUnits,
} from 'src/utils/transformers'

import client from './client'

export async function getShipments(params: {
  filter?: string
  search?: string
}): Promise<ShipmentPreview[]> {
  const response = await client.get('/api/internal/shipment/', {
    params,
  })

  // transform the order response into the app's object
  const shipments = response.data
  return shipments.map(transformShipmentPreview)
}

export async function getShipment(shipmentId: string): Promise<Shipment> {
  const response = await client.get(`/api/internal/shipment/${shipmentId}/`)
  return transformShipment(response.data)
}

export async function getShipmentsByOrder(
  orderId?: string
): Promise<ShipmentPreviewUnits[]> {
  const response = await client.get(
    `/api/internal/orders/${orderId}/shipments/`
  )
  return response.data.map(transformShipmentPreviewUnits)
}

export async function saveShipmentsByOrder(
  orderId: string,
  payload: {
    id?: string
    shipment_units: number[]
  }[]
): Promise<{ error?: string }> {
  const response = await client.post(
    `/api/internal/orders/${orderId}/shipments/`,
    payload
  )
  return response.data
}

export async function assignPacker(
  shipmentId: string
): Promise<FulfillmentOperator[]> {
  const response = await client.patch(
    `/api/internal/shipment/${shipmentId}/assign_packer/`
  )
  return response.data
}

export async function getPackingLabel(
  shipmentId: string
): Promise<PrinterLabel> {
  const response = await client.get(
    `/api/printerlabel/packing_label/${shipmentId}/`
  )
  return response.data
}

export async function unassignPacker(shipmentId: string): Promise<void> {
  await client.patch(`/api/internal/shipment/${shipmentId}/unassign_packer/`)
}

export async function commitPackage(shipmentId: string): Promise<void> {
  await client.patch(`/api/internal/shipment/${shipmentId}/commit_package/`)
}
