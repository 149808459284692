import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { action, makeObservable, observable, runInAction } from 'mobx'

import { LiveSearchStatus } from 'src/components/LiveSearch'
import { PACKAGING_VIEW_POLL_PERIOD_MS } from 'src/constants'
import {
  ApiStatus,
  Shipment,
  ShipmentFilter,
  ShipmentPreview,
} from 'src/models/shipment'
import PollService from 'src/services/PollService'
import * as ShipmentService from 'src/services/ShipmentService'

import LocalCacheStore from './LocalCacheStore'

class ShipmentsStore extends LocalCacheStore<ShipmentPreview, Shipment> {
  @observable selectedShipmentStatus: ApiStatus = ApiStatus.UNLOADED
  @observable searchId = ''
  @observable searchFilter?: ShipmentFilter = undefined
  @observable searchStatus: LiveSearchStatus = LiveSearchStatus.READY
  @observable audioAnnouncedShipments = new Set<string>()

  selectedShipmentPollService: PollService<string, Shipment | undefined> =
    new PollService()

  constructor() {
    super()
    makeObservable(this)
  }

  get pollConfig() {
    return {
      poll: true,
      name: 'shipments',
      interval: PACKAGING_VIEW_POLL_PERIOD_MS,
    }
  }

  get properties(): (keyof this)[] {
    return ['entityList', 'detailedEntities']
  }

  // Implementation of abstract methods.
  protected refreshEntityList(): Promise<ShipmentPreview[]> {
    // Override the filter if an order, shipment, or package id is specified.
    const params = !isEmpty(this.searchId)
      ? { search: this.searchId }
      : { filter: this.searchFilter }
    try {
      return ShipmentService.getShipments(params)
    } catch (e) {
      console.log(e)
      return Promise.resolve([])
    }
  }

  protected getEntity(entityId: string): Promise<Shipment> {
    return ShipmentService.getShipment(entityId)
  }

  protected createEntity(shipment: Shipment): Promise<Shipment> {
    throw Error('not supported')
  }

  protected updateEntity(shipment: Shipment): Promise<Shipment> {
    throw Error('not supported')
  }

  protected deleteEntity(shipment: Shipment): Promise<Shipment> {
    throw Error('not supported')
  }

  @action.bound startPollingShipments(filter: ShipmentFilter): void {
    this.searchFilter = filter
    this.startPolling()
  }

  @action.bound stopPollingShipments(): void {
    this.stopPolling()
    this.searchId = ''
    this.searchStatus = LiveSearchStatus.READY
  }

  @action.bound async filterShipmentsById(
    searchId: string,
    filter?: ShipmentFilter
  ): Promise<void> {
    this.searchId = searchId.trim()
    this.searchStatus = LiveSearchStatus.LOADING
    this.searchFilter = filter
    await this.refreshAll()
    this.searchStatus = isEmpty(this.searchId)
      ? LiveSearchStatus.READY
      : LiveSearchStatus.SUCCESS
  }

  @action.bound startPollingSelectedShipment(
    shipmentId: string,
    pollPeriod = 5000
  ): void {
    this.selectedShipmentPollService.startPolling(
      () => this.refreshSelectedShipment(),
      shipmentId,
      pollPeriod
    )
  }

  @action.bound stopPollingSelectedShipment(): void {
    if (this.selectedEntityId) {
      this.selectedShipmentPollService.stopPolling(this.selectedEntityId)
    }
  }

  @action.bound async refreshSelectedShipment(): Promise<void> {
    if (this.selectedEntityId) {
      await this.selectShipment(this.selectedEntityId, true)
    }
  }

  @action.bound async selectShipment(
    shipmentId?: string,
    refresh = false
  ): Promise<Shipment | undefined> {
    // Prevent querying an already loaded shipment
    const alreadyLoaded = shipmentId === this.selectedEntityId
    if (!refresh && alreadyLoaded) {
      return undefined
    } else if (!alreadyLoaded) {
      // If this is a new shipment, stop polling the last one.
      this.stopPollingSelectedShipment()
    }

    if (shipmentId !== undefined) {
      if (!refresh) {
        this.selectedShipmentStatus = ApiStatus.LOADING
      }

      try {
        await this.refresh(shipmentId)
        // prevent setting shipment details from an old request
        if (this.selectedEntityId === shipmentId) {
          runInAction(() => {
            this.selectedShipmentStatus = ApiStatus.LOADED
          })
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
          runInAction(() => {
            this.selectedShipmentStatus = ApiStatus.ERROR
          })
        } else {
          throw e
        }
      }
    } else {
      this.refresh(shipmentId)
      this.selectedShipmentStatus = ApiStatus.UNLOADED
    }

    return undefined
  }

  @action.bound addAudioAnnouncedShipment(shipmentId: string) {
    this.audioAnnouncedShipments.add(shipmentId)
  }

  @action.bound clearAudioAnnouncedShipments() {
    this.audioAnnouncedShipments = new Set()
  }
}

export default new ShipmentsStore()
