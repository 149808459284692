import React from 'react'

import Alert, { AlertProps } from 'react-bootstrap/Alert'

import { Variant } from 'src/constants'

interface StickyBannerProps {
  variant?: Variant
}

const StickyBanner: React.FC<StickyBannerProps & AlertProps> = ({
  children,
  className = '',
  variant = Variant.DANGER,
}) => {
  return (
    <Alert variant={variant} className={`sticky-top ${className}`}>
      {children}
    </Alert>
  )
}

export default StickyBanner
