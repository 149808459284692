import { AuthConfig } from 'src/models/config'
import { AuthUser } from 'src/models/users'
import { transformAuthUser } from 'src/utils/userTransformers'

import client from './client'

export async function getUser(): Promise<AuthUser> {
  const response = await client.get('/api/authenticated/')
  return transformAuthUser(response?.data)
}

export async function loginCognitoUser(
  cognito_user_id: string
): Promise<AuthUser> {
  const payload = { cognito_user_id }
  const response = await client.post('/api/auth/login/cognito/', payload)
  return transformAuthUser(response?.data)
}

export async function logoutUser(): Promise<void> {
  await client.get('/api/auth/logout/')
}

export async function getAuthConfig(): Promise<AuthConfig> {
  const response = await client.get('/api/auth/cognito_config/')
  const config = response?.data
  return {
    region: config.region,
    userPoolId: config.user_pool_id,
    userPoolWebClientId: config.user_pool_web_client_id,
  }
}
