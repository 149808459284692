export interface User {
  id: number
  username: string
  name: string
  email?: string
}

export interface AuthUser extends User {
  groups: string[]
  isSuperuser: boolean
}

export interface FulfillmentOperator extends User {
  displayName?: string
}

export interface Orderer extends User {
  facilityId: number
  recipientIds: number[]
  phoneNumber: string
  displayName: string
  isDefaultMsgRecipient: boolean
  isActive: boolean
}

export enum UserType {
  RECIPIENT_CONTACT = 'contact',
  ORDERER = 'orderer',
  FULFILLMENT_OPERATOR = 'fulfillment_operator',
}

export enum UserGroupType {
  FULFILLMENT_OPERATOR = 'FulfillmentOperator',
  FULFILLMENT_LEAD = 'FulfillmentLead',
}
