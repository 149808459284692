import React from 'react'

import 'core-js/stable'
import localForage from 'localforage'
import { configure } from 'mobx'
import { createRoot } from 'react-dom/client'
import 'regenerator-runtime/runtime'
import 'src/vendor/dymo-js-sdk.js'

import App from 'src/App'
import GlobalStore from 'src/stores/GlobalStore'

/* A new store is saved per version. For backwards-incompatible changes,
 * increment this version. */
const STORE_VERSION = 'v1'

if (process.env.NODE_ENV !== 'production') {
  // Turn on mobx linting in develop mode. This will print warnings to the console.
  // TODO(ivan): Fix warnings and enable observableRequiresReaction.
  configure({
    enforceActions: 'always',
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    //observableRequiresReaction: true,
    disableErrorBoundaries: true,
  })
}

// Configure localForage with IndexedDB.
// TODO SWS-3184: Delete old stores when upgrading versions.
localForage.config({
  name: 'Fulfillment',
  storeName: `stores-${STORE_VERSION}`,
})

GlobalStore.configureAuth()

const mountNode = document.getElementById('app')
const root = createRoot(mountNode!)
root.render(<App />)

// Enable hot module reloading for dev
if (module.hot) {
  module.hot.accept()
}
