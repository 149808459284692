import React from 'react'

import Form from 'react-bootstrap/Form'
import { FormControlProps } from 'react-bootstrap/FormControl'
import { FormGroupProps } from 'react-bootstrap/FormGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import Spinner from 'react-bootstrap/Spinner'
import { Check, Search, X } from 'react-feather'

export enum LiveSearchStatus {
  READY = 0,
  LOADING = 1,
  SUCCESS = 2,
  ERROR = 3,
}

interface LiveSearchProps {
  label?: string
  placeholder?: string
  onInputChange: (input: string) => void
  status: LiveSearchStatus
  className?: string
  style?: React.CSSProperties
  trim?: boolean
  autocomplete?: boolean
  name?: string
}

const getStatusDisplay = (
  status: LiveSearchStatus | string
): (() => React.ReactElement) => {
  switch (status) {
    case LiveSearchStatus.READY:
      return () => <Search size="18" />
    case LiveSearchStatus.LOADING:
      return () => <Spinner animation="border" size="sm" />
    case LiveSearchStatus.SUCCESS:
      return () => <Check size="18" />
    case LiveSearchStatus.ERROR:
      return () => <X size="18" />
    default:
      return () => <div>{status}</div>
  }
}

const LiveSearch: React.FC<LiveSearchProps & FormControlProps> = ({
  label,
  onInputChange,
  placeholder,
  status,
  value,
  disabled,
  trim,
  autocomplete = true,
  name = undefined,
  ...props
}) => {
  const StatusDisplay = getStatusDisplay(status)
  const key = `liveSearch-${name || label}`

  return (
    <Form.Group controlId={key} {...(props as FormGroupProps)}>
      {label && <Form.Label>{label}</Form.Label>}

      <InputGroup>
        <Form.Control
          autoFocus
          name={name || 'liveSearch'}
          autoComplete={autocomplete ? undefined : 'chrome-off'}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(event: React.FormEvent<any>) => {
            const input = event.currentTarget.value
            onInputChange(trim ? input.trim() : input)
          }}
        />

        <InputGroup.Append>
          <InputGroup.Text>
            <StatusDisplay />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  )
}

export default LiveSearch
