import React, { ReactNode, Suspense, useEffect, useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { observer } from 'mobx-react'
import Button from 'react-bootstrap/Button'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import AppNavbar from 'src/components/AppNavbar'
import BarcodeScannerPopover from 'src/components/BarcodeScannerPopover'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Spinner from 'src/components/Spinner'
import NetworkErrorBanner from 'src/components/banners/NetworkErrorBanner'
import ServiceWorkerRefreshBanner from 'src/components/banners/ServiceWorkerRefreshBanner'
import { Variant } from 'src/constants'
import AuthStore from 'src/stores/AuthStore'
import GlobalStore from 'src/stores/GlobalStore'
import ShipmentsStore from 'src/stores/ShipmentsStore'
import ViewStore, { ViewId } from 'src/stores/ViewStore'
import env from 'src/utils/environment'
import { listenForWaitingServiceWorker } from 'src/utils/serviceWorker'

import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'

const StyledToast = styled(ToastContainer)`
  width: auto !important;
`

const Fallback = (
  error: { message: string },
  clearCache: () => void
): ReactNode => (
  <div className="m-4">
    <p>
      An error has occurred! Please refresh the Fulfillment System if this
      persists.
    </p>
    <p className="alert-danger">{error.message}</p>
    <Button onClick={clearCache} variant={Variant.DANGER}>
      Clear cache and refresh
    </Button>
    <p className="alert-info mt-4">
      Note: Clearing the cache will permanantly delete all offline data that has
      not been synced
    </p>
  </div>
)

if (env.ENVIRONMENT === 'production') {
  datadogRum.init({
    applicationId: 'f7461606-4a01-458f-b5e5-08ae8d1fa110',
    clientToken: 'pub9a348f16d9396386aadbfa026e331144',
    site: 'datadoghq.com',
    service: 'fulfillment',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
  })
  datadogRum.startSessionReplayRecording()
}

const App: React.FC = observer(() => {
  const [didMount, setDidMount] = useState(false)
  useEffect(() => {
    setDidMount(true)

    return () => {
      // Clear audio announcement tracking list
      ShipmentsStore.clearAudioAnnouncedShipments()
    }
  }, [])

  useEffect(() => {
    const checkLoggedInUser = async () => {
      let user = AuthStore.currentUser
      if (!user) {
        // Check the backend to see if there is a logged in user
        user = await AuthStore.getCurrentUser()
      }

      if (!user) {
        // redirect to login page
        ViewStore.showView(ViewId.LOGIN)
      } else {
        // initialize app only if a user is logged in
        if (!GlobalStore.appReady) GlobalStore.initApp()
      }
    }

    if (didMount && !AuthStore.currentUser) {
      ViewStore.showView(ViewId.LOGIN)
    } else if (ViewStore.currentView.id != ViewId.OAUTH_CALLBACK) {
      // If currently on the OauthCallback page, don't bother checking logged in user
      // since the callback page will take care of loggin in and setting current user
      checkLoggedInUser()
    }
  }, [AuthStore.currentUser])

  useEffect(() => {
    if (env.ENVIRONMENT === 'production' && 'serviceWorker' in navigator) {
      // Use the window load event to keep the page load performant
      navigator.serviceWorker.register('/v3/service-worker.js').then((reg) => {
        function promptUserToRefresh(reg: ServiceWorkerRegistration): void {
          GlobalStore.setServiceWorkerUpdate(() => {
            reg.waiting?.postMessage({ type: 'SKIP_WAITING' })
          })
        }
        listenForWaitingServiceWorker(reg, promptUserToRefresh)
      })
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload()
        GlobalStore.setServiceWorkerUpdate(null)
      })
    }
  }, [])

  const ViewComponent = ViewStore.currentView.component()

  return (
    <ErrorBoundary fallback={Fallback}>
      {[ViewId.LOGIN, ViewId.OAUTH_CALLBACK].includes(
        ViewStore.currentView.id
      ) ? ( // Just show auth pages without nav/toast/suspense
        <ErrorBoundary fallback={Fallback}>
          <ViewComponent key="currentView" {...ViewStore.currentView.data} />
        </ErrorBoundary>
      ) : (
        <>
          <AppNavbar />
          {!ViewStore.isOnline ? (
            <NetworkErrorBanner />
          ) : GlobalStore.serviceWorkerUpdate ? (
            <ServiceWorkerRefreshBanner
              onUpdate={GlobalStore.serviceWorkerUpdate}
            />
          ) : null}

          <StyledToast
            position="top-right"
            autoClose={10000}
            closeOnClick={false}
            draggable={false}
          />
          <main className={ViewStore.currentView?.flushPadding ? '' : 'm-3'}>
            {GlobalStore.appReady ? (
              <Suspense
                fallback={
                  <Spinner size="sm">
                    Loading {ViewStore.currentView.id} View...
                  </Spinner>
                }
              >
                <ErrorBoundary fallback={Fallback}>
                  <ViewComponent
                    key="currentView"
                    {...ViewStore.currentView.data}
                  />
                </ErrorBoundary>
              </Suspense>
            ) : (
              <Spinner size="sm">Loading Fulfillment System...</Spinner>
            )}
          </main>

          <BarcodeScannerPopover />
        </>
      )}
    </ErrorBoundary>
  )
})

export default App
