import { UiConfig } from 'src/models/config'

import client from './client'

export async function getUiConfig(): Promise<UiConfig> {
  const response = await client.get('/api/ui_config/')
  const {
    nest_id: nestId,
    nest_key: nestKey,
    nest__country_code: countryCode,
    nest__distribution_center_name: distributionCenterName,
    nest__zipline_contact_number: ziplinePhoneNumber,
    validations__donation_id_regex: donationIdRegex,
    notifications__global_alert_message: globalAlertMessage,
    ...featureFlags
  } = response.data
  return {
    // These global settings pertain to the entire app and are fixed.
    countryCode,
    nestId,
    nestKey,
    distributionCenterName,
    ziplinePhoneNumber,
    donationIdRegex: new RegExp(donationIdRegex),
    globalAlertMessage,

    // Group all remaining options into a new object.
    featureFlags: featureFlags,
  }
}
