import { AuthUser, User } from 'src/models/users'

export function transformUser(user: any): User {
  return {
    id: user.id,
    username: user.username,
    name: user.name,
    email: user.email,
  }
}

export function transformAuthUser(user: any): AuthUser {
  return {
    ...transformUser(user),
    groups: user.groups,
    isSuperuser: user.is_superuser,
  }
}
