import React, { useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button'
import Col, { ColProps } from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'

import { ButtonVariant } from 'src/constants'

export const COLLAPSE_EVENT = 'oncollapse'

const TRANSITION_TIME_MS = 300

enum ChevronDirection {
  UP = 0,
  DOWN = 1,
}

interface AnimatedChevronProps {
  direction: ChevronDirection
}

const AnimatedChevron = styled(ChevronDown)`
  transition: all ${TRANSITION_TIME_MS}ms ease;
  transform: ${(props: AnimatedChevronProps) =>
    props.direction === ChevronDirection.DOWN ? 'none' : 'rotate(-180deg)'};
`

export const ColWithTransition = styled(Col)`
  transition: all ${TRANSITION_TIME_MS}ms ease;
`

export const AnimatingCol: React.FC<ColProps> = ({ children, ...props }) => {
  useEffect(() => {
    // Send an event whenever the size of the col changes.
    const event = new Event(COLLAPSE_EVENT)
    setTimeout(() => window.dispatchEvent(event), TRANSITION_TIME_MS)
  }, [props.xs, props.sm, props.md, props.lg, props.xl])

  return <ColWithTransition {...props}>{children}</ColWithTransition>
}

interface CollapsibleProps {
  header: React.ReactFragment
  defaultOpen?: boolean
  onToggle?: () => void
}

/** A collapsible component with a header and collapsed content. */
const Collapsible: React.FC<
  CollapsibleProps & React.HTMLAttributes<HTMLDivElement>
> = ({ header, defaultOpen = true, children, onToggle, ...props }) => {
  const [open, setOpen] = useState<boolean>(defaultOpen)

  const toggleCollapse = () => {
    setOpen(!open)
    onToggle?.()
    const event = new Event(COLLAPSE_EVENT)
    setTimeout(() => window.dispatchEvent(event), TRANSITION_TIME_MS)
  }

  return (
    <div {...props}>
      <Button onClick={toggleCollapse} variant={ButtonVariant.LINK}>
        <h5 className="text-dark">
          {header}{' '}
          <AnimatedChevron
            direction={open ? ChevronDirection.UP : ChevronDirection.DOWN}
          />
        </h5>
      </Button>
      <Collapse in={open}>
        <div>{children}</div>
      </Collapse>
    </div>
  )
}

export default Collapsible
