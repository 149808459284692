export enum ContactType {
  PHYSICIAN = 'Physician',
  DISTRICT_MANAGER = 'District Manager',
  FINANCE = 'Finance',
  FACILITY = 'Facility',
  OTHER = 'Other',
}

export enum ContactGroup {
  STAFF = 'staff',
  DISTRICT_MANAGERS = 'districtManagers',
  ORDERERS = 'orderers',
}

export default interface Contact {
  id: number
  name: string
  contactType?: ContactType
  phoneNumber?: string
  email?: string
  isDefaultMsgRecipient?: boolean
  facilityId?: number
  district?: string // name of the district
}
