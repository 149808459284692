import React from 'react'

import { Variant } from 'src/constants'
import env from 'src/utils/environment'

import StickyBanner from './StickyBanner'

const ServiceWorkerRefreshBanner: React.FC<{ onUpdate: () => void }> = ({
  onUpdate,
}) => (
  <StickyBanner
    className="d-flex justify-content-between"
    variant={Variant.INFO}
  >
    <div>A new Fulfillment version, {env.VERSION}, is available!</div>
    <a href="#" onClick={onUpdate}>
      Refresh to Update
    </a>
  </StickyBanner>
)

export default ServiceWorkerRefreshBanner
