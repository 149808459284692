import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import isEmpty from 'lodash/isEmpty'
// TODO: Enable once @types/react-widgets has type support for this module.
// @ts-ignore
import configure from 'react-widgets/lib/configure'

// These are the standard thresholds used by dayjs for relative time.
// https://github.com/iamkun/dayjs/blob/bdcc336613c9fa466b385574e88d0b43629475bc/src/plugin/relativeTime/index.js#L24
// The 'mm' and lower thresholds has been customized to show up-to-the-minute time under an hour.
const thresholds = [
  { l: 's', r: 1, d: 'second' },
  { l: 'm', r: 1 },
  { l: 'mm', r: 59, d: 'minute' },
  { l: 'h', r: 89 },
  { l: 'hh', r: 21, d: 'hour' },
  { l: 'd', r: 35 },
  { l: 'dd', r: 25, d: 'day' },
  { l: 'M', r: 45 },
  { l: 'MM', r: 10, d: 'month' },
  { l: 'y', r: 17 },
  { l: 'yy', d: 'year' },
]
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime, { thresholds })
dayjs.extend(utc)
dayjs.extend(duration)

export const UTC_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm'

const dayjsLocalizer = {
  firstOfWeek(): number {
    // TODO(ivan.wang): Depending on the locale, we may want to switch to 0 (Sun).
    return 1 // Monday
  },

  parse(value: string, format: string): Date | null {
    if (!value) {
      return null
    }

    const day = dayjs(value, format)
    return day.isValid() ? day.toDate() : null
  },

  format(value: Date, format: string): string {
    return dayjs(value).format(format)
  },

  formats: {
    default: 'lll',
    date: 'L',
    time: 'LT',
    header: 'MMMM YYYY',
    footer: 'LL',
    weekday: 'dd',
    dayOfMonth: 'DD',
    month: 'MMM',
    year: 'YYYY',

    decade(date: Date, _: string, localizer: any) {
      const endOfDecade = localizer.format(
        dayjs(date).add(10, 'year').add(-1, 'ms'),
        'YYYY'
      )

      return localizer.format(date, 'YYYY') + ' - ' + endOfDecade
    },

    century(date: Date, _: string, localizer: any) {
      const endOfCentury = localizer.format(
        dayjs(date).add(100, 'year').add(-1, 'ms'),
        'YYYY'
      )

      return localizer.format(date, 'YYYY') + ' - ' + endOfCentury
    },
  },
}

configure.setDateLocalizer(dayjsLocalizer)

export function displayLocalizedTime(
  time: string | number
): string | undefined {
  return typeof time === 'string' && isEmpty(time)
    ? undefined
    : dayjs(time).format('llll')
}

export function displayRelativeTime(timeStr: string): string | undefined {
  return isEmpty(timeStr) ? undefined : dayjs().to(timeStr)
}

export function dateTimeFromNow(offsetS: number): Date {
  return dayjs().add(offsetS, 's').toDate()
}

export function displayDate(
  timeStr: string | number | Date
): string | undefined {
  return isEmpty(timeStr) &&
    typeof timeStr !== 'number' &&
    !(timeStr instanceof Date)
    ? undefined
    : dayjs(timeStr).format('YYYY-MM-DD')
}

export function displayTime(time: string | number): string | undefined {
  return isEmpty(time) && typeof time !== 'number'
    ? undefined
    : dayjs(time).format('hh:mm A')
}

export function displayDateTime(time?: string): string | undefined {
  if (isEmpty(time)) {
    return undefined
  }

  const timeObj = dayjs(time)
  return timeObj.format(
    // Show the date only if it's on a different day.
    timeObj.isSame(dayjs(), 'day') ? 'hh:mm A' : 'MM-DD hh:mm A'
  )
}

export function localToUtc(localTimeStr: string): string {
  return dayjs.utc(dayjs(localTimeStr)).format()
}

export function utcToDate(utcTimeStr?: string | null): Date | undefined {
  return utcTimeStr ? dayjs.utc(utcTimeStr).local().toDate() : undefined
}

export function dateToUtc(date?: Date): string | null {
  return date ? dayjs.utc(dayjs(date)).format() : null
}

export function utcToLocal(utcTimeStr: string, format?: string): string {
  return dayjs.utc(utcTimeStr).local().format(format)
}

export function secondToMinute(value: string | number, roundup = true) {
  const converted = dayjs.duration(value, 'seconds').asMinutes()
  if (roundup) {
    return Math.round(converted)
  }
  return converted
}

export function convertToISODayString(date: Date) {
  return dayjs(date).format('YYYY-MM-DD')
}

export function getDaysFromNow(date: Date | string): number {
  const now = dayjs()
  return dayjs(date).diff(now, 'day')
}

export function isCurrentTimeWithin(startTimeStr: string, endTimeStr: string) {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  // js month starts from 0 so adding 1;
  // padding with 0 for single digit month or day; slicing in case it is double digit
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
  const day = ('0' + currentDate.getDate()).slice(-2)

  // create Date object for start and end times
  const startTime = new Date(
    year + '-' + month + '-' + day + 'T' + startTimeStr
  )
  const endTime = new Date(year + '-' + month + '-' + day + 'T' + endTimeStr)

  // account for end time being on the next day
  if (startTime > endTime) {
    endTime.setDate(endTime.getDate() + 1)
  }

  return currentDate >= startTime && currentDate <= endTime
}

export function isValidDateTimeFormat(
  dateTime: string,
  formatStr: string,
  strict = false
) {
  return dayjs(dateTime, formatStr, strict).isValid()
}

export function isTomorrowOrLater(datetime: string) {
  return dayjs(datetime).isAfter(dayjs(), 'day')
}
