import React from 'react'

import InfoBadge from 'src/components/InfoBadge'

import StickyBanner from './StickyBanner'

const NetworkErrorBanner: React.FC = () => (
  <StickyBanner className="d-flex justify-content-between">
    <div>There was an error connecting to the internet. Retrying...</div>
    <InfoBadge id="network-info" placement="left" color="var(--danger)">
      All of your data have been saved locally and will be synced the next time
      we reconnect.
    </InfoBadge>
  </StickyBanner>
)

export default NetworkErrorBanner
