interface Environment {
  ENVIRONMENT: 'development' | 'preprod' | 'staging' | 'production'
  VERSION: string
  REACT_APP_PUBLIC_COGNITO_CLIENT_ID: string
  REACT_APP_PUBLIC_COGNITO_REGION: string
  REACT_APP_PUBLIC_COGNITO_USER_POOL_ID: string
}

declare global {
  interface Window {
    __env__: Environment
  }
}

export default window.__env__ ?? {}
