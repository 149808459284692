import keyBy from 'lodash/keyBy'
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  runInAction,
} from 'mobx'

import { ProductGroup as ProductGroupName } from 'src/constants'
import { FeatureFlagName } from 'src/models/config'
import { Product } from 'src/models/product'
import { ProductGroup } from 'src/models/product_group'
import * as ProductService from 'src/services/ProductService'
import GlobalStore from 'src/stores/GlobalStore'
import LocalCacheStore from 'src/stores/LocalCacheStore'

class ProductStore extends LocalCacheStore<Product, Product> {
  @observable productGroups?: Record<string, ProductGroup>

  constructor() {
    super()
    makeObservable(this)
  }

  get properties(): (keyof this)[] {
    return ['entityList', 'productGroups']
  }

  @computed get products(): Product[] {
    // The first time this is computed, lazily fetch from the server.
    if (this.loadingEntities) {
      this.refreshAll()
    }
    return this.entityList
  }

  @computed get loadingProducts(): boolean {
    return this.loadingEntities
  }

  @computed get groupsWithProducts(): ProductGroupName[] {
    if (!this.productGroups) {
      return []
    }

    return Object.values(this.productGroups).reduce(
      (names: ProductGroupName[], group) => {
        if (group.products.length) {
          names.push(group.name)
        }
        return names
      },
      []
    )
  }

  @computed get productsBySku(): Record<string, Product> {
    if (!this.products) {
      return {}
    }

    return keyBy(this.products, 'sku')
  }

  // Implementation of abstract methods.
  protected refreshEntityList(): Promise<Product[]> {
    return ProductService.getProducts()
  }

  @override async refreshAll(): Promise<Product[]> {
    if (this._currentRefresh) {
      return this._currentRefresh
    }

    // Fetch product groups.
    this.getProductGroups()
    return super.refreshAll()
  }

  protected getEntity(entityId: string): Promise<Product> {
    throw Error('not supported')
  }

  protected createEntity(product: Product): Promise<Product> {
    throw Error('not supported')
  }

  protected updateEntity(product: Product): Promise<Product> {
    throw Error('not supported')
  }

  protected deleteEntity(product: Product): Promise<Product> {
    throw Error('not supported')
  }

  @action.bound async getProductGroups(): Promise<
    Record<string, ProductGroup>
  > {
    const productGroups = await ProductService.getProductGroups()
    runInAction(() => (this.productGroups = productGroups))
    return productGroups
  }

  getDisplayLabel = (product: Product | undefined) => {
    if (!product) return ''
    const { sku = '', name = '' } = product
    if (
      sku &&
      GlobalStore.getFeatureFlag(FeatureFlagName.SEARCH_BY_SKU_ENABLED)
    ) {
      if (name) {
        return `${sku}: ${name}`
      } else {
        return sku
      }
    }
    return name
  }
}

export default new ProductStore()
