import Contact from 'src/models/contact'
import {
  transformContact,
  transformContactPayload,
} from 'src/utils/transformers'

import client from './client'

export async function getContacts(): Promise<Contact[]> {
  const response = await client.get('/api/internal/recipient_contact/')
  return response.data.map(transformContact)
}

export async function createContact(contact: Contact): Promise<Contact> {
  const response = await client.post(
    '/api/internal/recipient_contact/',
    transformContactPayload(contact)
  )
  return transformContact(response.data)
}

export async function updateContact(contact: Contact): Promise<Contact> {
  const response = await client.patch(
    `/api/internal/recipient_contact/${contact.id}/`,
    transformContactPayload(contact)
  )
  return transformContact(response.data)
}
