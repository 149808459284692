import { Notification } from 'src/models/notification'
import { transformNotification } from 'src/utils/transformers'

import client from './client'

export async function getNotifications(): Promise<Notification[]> {
  const response = await client.get('/api/notification/')
  return response?.data.map(transformNotification)
}

export async function createNotification(
  notification: Omit<Notification, 'id'>
): Promise<boolean> {
  await client.post('/api/notification/', {
    entity_id: notification.entityId,
    message: notification.message,
    timestamp: notification.timestamp,
    notification_type: notification.notificationType,
  })
  return true
}
