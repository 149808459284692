import React, { useEffect, useState } from 'react'

import localForage from 'localforage'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Modal, { ModalProps } from 'react-bootstrap/Modal'

import DatadogSessionTracker from 'src/components/DatadogSessionTracker'
import Link from 'src/components/Link'
import { Variant } from 'src/constants'
import { FeatureFlagName } from 'src/models/config'
import GlobalStore from 'src/stores/GlobalStore'

const HelpModal: React.FC<ModalProps> = ({ show, onHide }) => {
  const [storageUsage, setStorageUsage] = useState<string>('')
  const getNavigatorStorage = async () => {
    if (!navigator || !show) {
      return
    }
    const { usage } = await navigator.storage.estimate()
    if (usage !== undefined) {
      setStorageUsage(`${Math.ceil(usage / 1000000)}MB`)
    }
  }

  useEffect(() => void getNavigatorStorage(), [show])

  // Clears the IndexedDB cache of offline mobx stores.
  const clearCache = async () => {
    await localForage.clear()
    await getNavigatorStorage()
    window.location.reload()
  }

  /** TODO(ivan): Consider moving these into feature flags if they change frequently. */
  const HELP_LINKS: { label: string; url: string }[] = [
    {
      label: 'Fulfillment System Manual',
      url: 'https://flyzipline.atlassian.net/wiki/spaces/FS/pages/1412923865/Fulfillment+System+Manual',
    },
    {
      label: 'Healthcare Quality Documentation',
      url: 'https://drive.google.com/drive/folders/1sCQer1QNrZoSsAudWvviOTNncBrpNP5T',
    },
    {
      label: 'Challenging Experience Process Guide',
      url: 'https://docs.google.com/document/d/1xlRnQEVYaHRR1xdQUq0lfcDWLCnYkuCoDyEGFxxs1QI/edit?usp=sharing',
    },
    {
      label: 'File a ticket: Fulfillment System issue',
      url: GlobalStore.getFeatureFlag(
        FeatureFlagName.FULFILLMENT_OPERATIONS_SERVICE_DESK_LINK_ENABLED
      )
        ? 'https://flyzipline.atlassian.net/servicedesk/customer/portal/1037'
        : 'https://flyzipline.atlassian.net/servicedesk/customer/portal/1/group/11/create/165',
    },
    {
      label: 'File a ticket: Customer issue',
      url: 'https://flyzipline.atlassian.net/servicedesk/customer/portal/1/group/11/create/158',
    },
  ]

  return (
    <Modal onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Get Help</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-4">
        <h5>Encountering a problem with the Fulfilment System?</h5>
        <Alert variant={Variant.DANGER}>
          <p>
            If this is <strong>urgent</strong> and{' '}
            <strong>impacting operations</strong>, please type the following
            into Slack to page an engineer:
          </p>
          <pre className="mb-0">/get-ful-ops-help</pre>
        </Alert>
        <hr />
        <DatadogSessionTracker />
        <hr />
        <p>
          Encountering slowness? If this number is large (over 50 MB), you can
          try clearing the cache.
        </p>
        <div className="d-flex flex-column align-items-center">
          <div className="mb-1">
            Memory footprint: <b>{storageUsage ?? 'unknown'}</b>
          </div>
          <Button onClick={clearCache} variant={Variant.DANGER}>
            Clear cache and refresh
          </Button>
        </div>

        <hr />
        <p>Otherwise, you may find one of the links below helpful:</p>

        <ul>
          {HELP_LINKS.map(({ label, url }) => (
            <li key={label}>
              <Link newWindow href={url}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  )
}

export default HelpModal
