import React, { useState } from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { AlertCircle } from 'react-feather'
import { toast } from 'react-toastify'

import Link from 'src/components/Link'
import ProductGroupToggle from 'src/components/ProductGroupToggle'
import { renderTooltip } from 'src/components/Tooltip'
import { OutlineVariant, ProductGroup } from 'src/constants'
import { getUnitIdLabels } from 'src/services/UnitService'
import { printLabel } from 'src/utils/zebra'

const PrintUnitIdsModal: React.FC<ModalProps> = ({ show, onHide }) => {
  const [isPrinting, setPrinting] = useState(false)
  const [productGroup, setProductGroup] = useState<ProductGroup>(
    ProductGroup.BLOOD
  )
  const [count, setCount] = useState(10)

  const onPrintLabel = async () => {
    setPrinting(true)
    const { printOptions } = await getUnitIdLabels(productGroup, count)
    const response = await printLabel(printOptions)
    if (response.error) {
      toast.error(
        `An error occurred when attempting to print: ${response.error}`
      )
    }
    setPrinting(false)
  }

  return (
    <Modal onHide={onHide} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Print Unit IDs</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-muted">Print Unit Ids using the zebra printer.</p>
        <Form.Group as={Form.Row}>
          <Form.Label column sm={3}>
            Product Group
          </Form.Label>
          <Col sm={9}>
            <ProductGroupToggle
              name="productGroup"
              value={productGroup}
              onChange={(value: ProductGroup) => setProductGroup(value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Form.Row}>
          <Form.Label column sm={3}>
            Number of IDs
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="number"
              value={count.toString()}
              min={1}
              max={1000}
              onChange={(event: React.ChangeEvent<any>) =>
                setCount(event.target.value)
              }
            />
            <Form.Control.Feedback type="invalid">
              Must enter a positive quantity
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <OverlayTrigger
          placement="bottom"
          overlay={renderTooltip(
            'unit-id-tooltip',
            'Unused unit IDs are generated from the system starting from the "last used unit id". Modifying this value will increase or decrease the unit IDs in the downloaded CSV.'
          )}
        >
          <div className="mr-auto">
            <AlertCircle size={16} className="mr-2 text-info" />
            <Link
              href={`/admin/ordering/productgroup/${productGroup}/change/`}
              newWindow
            >
              Edit last used unit ID
            </Link>
          </div>
        </OverlayTrigger>

        <Button variant={OutlineVariant.SECONDARY} onClick={onHide}>
          Close
        </Button>
        <Button onClick={onPrintLabel} disabled={count < 1 || isPrinting}>
          {isPrinting ? 'Printing...' : 'Print IDs'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PrintUnitIdsModal
