import get from 'lodash/get'

import { ProductGroup } from 'src/constants'
import { NotificationType } from 'src/models/notification'
import { BasicProduct } from 'src/models/product'
import { ScriptCollection } from 'src/models/script'
import {
  FacilityStatus,
  FacilityStatusReason,
  FacilityStock,
  HealthFacility,
  HealthFacilityGroup,
  SafetyCheckStatus,
} from 'src/models/sites'
import {
  ProductLimitsCreationResponse,
  transformFacilityProductLimit,
  transformFacilityStock,
  transformHealthFacility,
  transformHealthFacilityGroup,
  transformHealthFacilityPayload,
} from 'src/utils/transformers'

import client from './client'

export interface NewHealthFacility {
  delivery_sites: number[]
  direct_delivery_site: number | null
  groups: string[]
  name: string
  notes?: string
  address: string
  address_extended: string
  zone?: string
  health_system_code?: string
  district: string
  region: string
  postcode: string
  country: string
  email: string
  id: number
  notification_types: NotificationType[]
  sms_notifications_enabled: boolean
  safety_check_status?: SafetyCheckStatus
  status: FacilityStatus
  status_reason?: number | null
  credit_check_status?: string | null
}

export async function getHealthFacilities(): Promise<HealthFacility[]> {
  const response = await client.get('/api/internal/health_facilities/')
  return response.data.map(transformHealthFacility)
}

export async function getDistricts(_nestKey: string): Promise<string[]> {
  const response = await client.get('/api/internal/districts/')
  return response.data.map((district: { name: string }) => district.name)
}

export async function getHealthFacilityGroups(): Promise<
  HealthFacilityGroup[]
> {
  const response = await client.get('/api/internal/health_facility_groups/')
  return response.data.map(transformHealthFacilityGroup)
}

// get FacilityStatusReasons
export async function getFacilityStatusReasons(): Promise<
  FacilityStatusReason[]
> {
  const response = await client.get('/api/internal/facility_status_reasons/')
  return response.data
}

export async function getHealthFacilityOrderableProducts(
  facilityId: number
): Promise<string[]> {
  const response = await client.get(
    `/api/internal/facility_orderable_products/${facilityId}/`
  )
  return response.data
}

export async function getOrderableProducts(
  groupId?: number,
  supplierName?: number,
  onlyStocked = false
): Promise<Record<string, BasicProduct[]>> {
  const data = {
    facility_group: groupId,
    supplier: supplierName,
    only_stocked: onlyStocked,
  }
  const response = await client.post(
    '/api/internal/health_facility_groups/orderable_products/',
    data
  )
  return response.data
}

export async function clearAllCreditChecks(): Promise<HealthFacility[]> {
  const response = await client.post(
    '/api/internal/health_facilities/clear_all_credit_checks/'
  )
  return response.data.map(transformHealthFacility)
}

export async function createHealthFacility(
  facility: HealthFacility
): Promise<HealthFacility> {
  const payload = transformHealthFacilityPayload(facility)
  const response = await client.post(
    '/api/internal/health_facilities/',
    payload
  )
  return transformHealthFacility(response.data)
}

export async function updateHealthFacility(
  facility: HealthFacility
): Promise<HealthFacility> {
  const payload = transformHealthFacilityPayload(facility)
  const response = await client.patch(
    `/api/internal/health_facilities/${facility.id}/`,
    payload
  )
  return transformHealthFacility(response.data)
}

export async function getScriptsForHealthFacility(
  facilityId: number,
  startDate: string,
  endDate?: string
): Promise<Record<string, ScriptCollection>> {
  const response = await client.get(
    `/api/customerscript/facilities/${facilityId}/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    }
  )
  return response.data
}

export async function getFacilityStock(): Promise<FacilityStock[]> {
  const response = await client.get('/api/customer/rbc/facility_inventory/')
  return response.data.map(transformFacilityStock)
}

export async function updateFacilityStock(
  facilityStock: FacilityStock
): Promise<FacilityStock> {
  const update = {
    facility: facilityStock.id,
    product: facilityStock.productId,
    stock: facilityStock.stockCount,
  }

  const response = await client.post(
    '/api/customer/rbc/facility_inventory/',
    update
  )
  return transformFacilityStock(response.data)
}

export async function getFacilityProductLimit(
  productSku: string,
  facilityId?: number
): Promise<number | null> {
  const queryParams = facilityId ? `?facility_id=${facilityId}` : ''
  const response = await client.get(
    `/api/internal/facility_product_limit/product/${productSku}/${queryParams}`
  )
  return response.data.limit
}

export async function generateProductLimitsByCsv(
  csv: File,
  receivedShipmentId: number,
  productGroup: ProductGroup
) {
  const formData = new FormData()
  formData.append('file', csv)
  try {
    const response = await client.post(
      `api/internal/facility_product_limit/limits_from_csv/?received_shipment_id=${receivedShipmentId}&product_group=${productGroup}`,
      formData
    )
    return transformFacilityProductLimit(response.data)
  } catch (e) {
    const error = get(
      e,
      'response.data.error',
      'Encountered an unexpected error.'
    )
    return { error }
  }
}

export async function createProductLimits(
  generatedLimitsResponse: ProductLimitsCreationResponse
) {
  const data = {
    received_shipment_id: generatedLimitsResponse.receivedShipmentId,
    limits_map: generatedLimitsResponse.limitsMap,
  }
  try {
    const response = await client.post(
      `api/internal/facility_product_limit/create_limits/`,
      data
    )
    return response.data
  } catch (e) {
    const error = get(
      e,
      'response.data.error',
      'Encountered an unexpected error.'
    )
    return { error }
  }
}

export async function countLimitsByReceivedShipment(
  receivedShipmentId: number
) {
  const response = await client.get(
    `api/internal/facility_product_limit/received_shipment/${receivedShipmentId}`
  )
  return response.data.count || 0
}
