import isUndefined from 'lodash/isUndefined'

import { isDocumentHidden } from 'src/utils/pageVisibility'
import { CancelablePromise, makeCancelable } from 'src/utils/promises'

interface CurrentPoll<T> {
  callback: () => Promise<T>
  pollPeriod: number
  promise?: CancelablePromise<T>
  timer?: number
}

/**
 * A service that allows polling for a callback every interval.
 * A unique timerKey is used to distinguish between different types of
 * callbacks. Note that only one callback may be polled at a time.
 */
class PollService<T, R> {
  pollKey?: T
  currentPoll?: CurrentPoll<R | void>

  /** Sets an interval for a callback every interval. */
  startPolling(
    callback: () => Promise<R | void>,
    pollKey: T,
    pollPeriod = 5000
  ): void {
    if (this.currentPoll) {
      if (this.pollKey === pollKey) {
        throw `Already polling ${pollKey}!`
      } else {
        this.stopPolling(this.pollKey!)
      }
    }

    this.pollKey = pollKey
    this.currentPoll = {
      callback,
      pollPeriod,
    }

    // start polling
    this.pollCallback()
  }

  pollCallback(): void {
    // stop polling once the callback has been removed
    if (isUndefined(this.currentPoll)) {
      return
    }

    // Wait for the next poll if the page is not visible.
    if (isDocumentHidden()) {
      this.currentPoll.timer = window.setTimeout(
        () => this.pollCallback(),
        this.currentPoll.pollPeriod
      )
      return
    }

    this.currentPoll.promise = makeCancelable(this.currentPoll.callback())
    this.currentPoll.promise.promise.then(() => {
      if (isUndefined(this.currentPoll)) {
        return
      }
      this.currentPoll.timer = window.setTimeout(
        () => this.pollCallback(),
        this.currentPoll.pollPeriod
      )
    })
  }

  /** Stops polling if the pollKey matches. */
  stopPolling(pollKey: T): void {
    if (this.currentPoll && this.pollKey === pollKey) {
      // clean up existing promises/timeouts
      if (this.currentPoll.promise) {
        this.currentPoll.promise.cancel()
      }
      if (this.currentPoll.timer) {
        clearTimeout(this.currentPoll.timer)
      }

      this.currentPoll = undefined
    }
  }
}

export default PollService
