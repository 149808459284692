import keyBy from 'lodash/keyBy'
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  runInAction,
} from 'mobx'

import { FacilityStatusReason, HealthFacility } from 'src/models/sites'
import * as HealthFacilityService from 'src/services/HealthFacilityService'
import GlobalStore from 'src/stores/GlobalStore'
import LocalCacheStore from 'src/stores/LocalCacheStore'

class HealthFacilityStore extends LocalCacheStore<
  HealthFacility,
  HealthFacility
> {
  @observable districts: string[] = []
  @observable facilityStatusReasons?: FacilityStatusReason[]

  constructor() {
    super()
    makeObservable(this)
  }

  get properties(): (keyof this)[] {
    return ['entityList', 'districts']
  }

  @computed get facilities(): HealthFacility[] {
    // The first time this is computed, lazily fetch from the server.
    if (this.loadingEntities) {
      this.refreshAll()
    }
    return this.entityList
  }

  @computed get loadingFacilities(): boolean {
    return this.loadingEntities
  }

  // Implementation of abstract methods.
  protected refreshEntityList(): Promise<HealthFacility[]> {
    return HealthFacilityService.getHealthFacilities()
  }

  @override async refreshAll(): Promise<HealthFacility[]> {
    if (this._currentRefresh) {
      return this._currentRefresh
    }

    // Fetch districts.
    this.getDistricts()
    // Fetch Facility Status Reasons.
    this.getFacilityStatusReasons()
    return super.refreshAll()
  }

  protected getEntity(entityId: number): Promise<HealthFacility> {
    throw 'not supported'
  }

  protected createEntity(
    healthFacility: HealthFacility
  ): Promise<HealthFacility> {
    return HealthFacilityService.createHealthFacility(healthFacility)
  }

  protected updateEntity(
    healthFacility: HealthFacility
  ): Promise<HealthFacility> {
    return HealthFacilityService.updateHealthFacility(healthFacility)
  }

  protected deleteEntity(
    healthFacility: HealthFacility
  ): Promise<HealthFacility> {
    throw Error('not supported')
  }

  @computed get facilitiesById(): Record<string, HealthFacility> {
    if (!this.facilities) {
      return {}
    }

    return {
      ...keyBy(this.facilities, 'id'),
      ...this.detailedEntities, // detailed entities should override
    }
  }

  @computed get facilityIdList(): Array<number> {
    if (!this.entityList) {
      return []
    }

    return this.entityList.map((entity) => entity.id)
  }

  @action.bound async getDistricts(): Promise<string[]> {
    const districts = await HealthFacilityService.getDistricts(
      GlobalStore.nestKey
    )
    runInAction(() => (this.districts = districts))
    return districts
  }

  @action.bound async getFacilityStatusReasons(): Promise<
    FacilityStatusReason[]
  > {
    const facilityStatusReasons =
      await HealthFacilityService.getFacilityStatusReasons()
    runInAction(() => (this.facilityStatusReasons = facilityStatusReasons))
    return facilityStatusReasons
  }

  // Does not update through the store and may cause issues with offline updates,
  // but we don't expect this function to be used often
  @action.bound async resetHealthFacilitiesCreditCheckStatus(): Promise<
    HealthFacility[]
  > {
    const facilities = await HealthFacilityService.clearAllCreditChecks()
    runInAction(() => (this.entityList = facilities))
    return facilities
  }

  getFacilityNameById(id: string): string {
    const facility = this.facilitiesById[id]
    return facility?.name || ''
  }
}

export default new HealthFacilityStore()
