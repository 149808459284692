import { Component, ReactNode, isValidElement } from 'react'

import localForage from 'localforage'

type FallbackRenderer = (
  error: Error,
  clearCache: () => void
) => React.ReactNode

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
  prevScope: string
}

export interface ErrorBoundaryProps {
  fallback: ReactNode | FallbackRenderer
  scope?: string
  children?: ReactNode
}

/**
 * ErrorBoundary component sends enriched errors to Datadog RUM.
 * Adapted from https://github.com/DataDog/rum-react-integration-examples/blob/master/src/ErrorBoundary/ErrorBoundary.ts
 */
export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    scope: 'error-boundary',
  }

  constructor(props: any) {
    super(props)
    this.state = { hasError: false, prevScope: props.scope }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  static getDerivedStateFromProps(
    props: ErrorBoundaryProps,
    state: ErrorBoundaryState
  ) {
    if (state.prevScope !== props.scope) {
      return {
        hasError: false,
        error: undefined,
        prevScope: props.scope,
      }
    }

    return state
  }

  componentDidCatch(error: Error) {
    /* @ts-ignore This is injected into the window by Datadog */
    const RumGlobal = window.DD_RUM
    if (RumGlobal) {
      RumGlobal.addError(
        error,
        {
          scope: this.props.scope,
        },
        'source'
      )
    }
  }

  render() {
    const { hasError, error } = this.state
    const { fallback } = this.props

    // Clears the IndexedDB cache of offline mobx stores.
    const clearCache = async () => {
      await localForage.clear()
      window.location.reload()
    }

    if (!hasError || !error) {
      return this.props.children
    }

    if (isValidElement(fallback) || typeof fallback === 'string') {
      return fallback
    } else if (typeof fallback === 'function') {
      return fallback(error, clearCache)
    }

    return null
  }
}
