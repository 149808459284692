import React, { forwardRef, useLayoutEffect } from 'react'

import { OverlayInjectedProps } from 'react-bootstrap/Overlay'
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip'

import { COLLAPSE_EVENT } from 'src/components/Collapsible'

export const renderTooltip =
  (id: string, children: React.ReactNode) => (props: OverlayInjectedProps) => (
    <Tooltip id={id} {...props}>
      {children}
    </Tooltip>
  )

/** Tooltip component that updates its position whenever something collapses. */
export const UpdatingTooltip = forwardRef<Tooltip, TooltipProps>(
  ({ popper, children, ...props }, ref) => {
    useLayoutEffect(() => {
      popper.scheduleUpdate()
      const onCollapse = () => popper.scheduleUpdate()
      window.addEventListener(COLLAPSE_EVENT, onCollapse)

      return () => {
        window.removeEventListener(COLLAPSE_EVENT, onCollapse)
      }
    }, [children, popper])

    return (
      <Tooltip ref={ref} {...props}>
        {children}
      </Tooltip>
    )
  }
)
