import React, { useState } from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { AlertCircle } from 'react-feather'

import Link from 'src/components/Link'
import ProductGroupToggle from 'src/components/ProductGroupToggle'
import { renderTooltip } from 'src/components/Tooltip'
import { OutlineVariant, ProductGroup } from 'src/constants'
import { getUnusedUnitIds } from 'src/services/UnitService'

const GetUnitIdsModal: React.FC<ModalProps> = ({ show, onHide }) => {
  const [isDownloading, setDownloading] = useState(false)
  const [productGroup, setProductGroup] = useState<ProductGroup>(
    ProductGroup.BLOOD
  )
  const [count, setCount] = useState(10)

  return (
    <Modal onHide={onHide} show={show} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Download Unit IDs</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-muted">
          Download a CSV with unused unit IDs, for use in printing labels.
        </p>
        <Form.Group as={Form.Row}>
          <Form.Label column sm={3}>
            Product Group
          </Form.Label>
          <Col sm={9}>
            <ProductGroupToggle
              name="productGroup"
              value={productGroup}
              onChange={(value: ProductGroup) => setProductGroup(value)}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Form.Row}>
          <Form.Label column sm={3}>
            Number of IDs
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="number"
              value={count.toString()}
              min={1}
              max={1000}
              isInvalid={count < 1}
              onChange={(event: React.ChangeEvent<any>) =>
                setCount(event.target.value)
              }
            />
            <Form.Control.Feedback type="invalid">
              Must enter a positive quantity
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <OverlayTrigger
          placement="bottom"
          overlay={renderTooltip(
            'unit-id-tooltip',
            'Unused unit IDs are generated from the system starting from the "last used unit id". Modifying this value will increase or decrease the unit IDs in the downloaded CSV.'
          )}
        >
          <div className="mr-auto">
            <AlertCircle size={16} className="mr-2 text-info" />
            <Link
              href={`/admin/ordering/productgroup/${productGroup}/change/`}
              newWindow
            >
              Edit last used unit ID
            </Link>
          </div>
        </OverlayTrigger>

        <Button variant={OutlineVariant.SECONDARY} onClick={onHide}>
          Close
        </Button>
        <Button
          onClick={async () => {
            setDownloading(true)
            await getUnusedUnitIds(productGroup, count)
            setDownloading(false)
          }}
          disabled={count < 1 || isDownloading}
        >
          {isDownloading ? 'Downloading...' : 'Download IDs'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GetUnitIdsModal
