import { action, makeObservable, observable } from 'mobx'

import { BasicUnitWithReturnDate } from 'src/models/unit'

type UnitId = BasicUnitWithReturnDate['id']

/* 
Barcode Scanner Store
This store backs the global barcode scanner component.
Any view can turn on the barcode scanner and listen to new scans.
We could even keep some historical scans around just for the UX of seeing what you've scanned.
*/

class BarcodeScannerStore {
  @observable isOn = false
  @observable lastScan?: string

  constructor() {
    makeObservable(this)
  }

  @action.bound turnOn(): void {
    this.lastScan = undefined
    this.isOn = true
  }
  @action.bound turnOff(): void {
    this.isOn = false
  }

  @action.bound onScan(scan: string): void {
    this.lastScan = scan
  }
}

export default new BarcodeScannerStore()
