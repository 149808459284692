import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import isUndefined from 'lodash/isUndefined'
import values from 'lodash/values'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { Order, OrderPreview, OrderValidation, Status } from 'src/models/order'
import { ScriptCollection } from 'src/models/script'
import * as OrderService from 'src/services/OrderService'
import ContactStore from 'src/stores/ContactStore'
import { SyncErrors } from 'src/stores/LocalCacheStore'
import OrdersStore from 'src/stores/OrdersStore'
import ViewStore, { ViewId } from 'src/stores/ViewStore'
import { isUpcomingScheduledOrder } from 'src/utils/order'

// How orders are grouped in the Customer Service View.
export enum StatusGroup {
  DRAFTS = 'Drafts',
  UNSYNCED = 'Unsynced',
  NEEDS_ATTENTION = 'Needs Attention',
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  DELIVERED = 'Delivered',
  UPCOMING = 'Upcoming',
  CANCELED = 'Canceled',
  NEEDS_SIGNATURE = 'Awaiting E-Signature',
}

const StatusToGroup: Record<Status, StatusGroup> = {
  [Status.DRAFT]: StatusGroup.DRAFTS,
  [Status.UNSYNCED]: StatusGroup.UNSYNCED,
  [Status.AWAITING_CREDIT_CHECK]: StatusGroup.NEEDS_ATTENTION,
  [Status.FAILED_CREDIT_CHECK]: StatusGroup.NEEDS_ATTENTION,
  [Status.NEEDS_PAYMENT]: StatusGroup.NEEDS_ATTENTION,
  [Status.NEEDS_NOTIFICATION]: StatusGroup.NEEDS_ATTENTION,
  [Status.NEEDS_CONFIRMATION]: StatusGroup.NEEDS_ATTENTION,
  [Status.NEEDS_RECEIPT_CONFIRMATION]: StatusGroup.NEEDS_ATTENTION,
  [Status.AWAITING_RESTOCK]: StatusGroup.PENDING,
  [Status.CONFIRMED]: StatusGroup.CONFIRMED,
  [Status.DELIVERED]: StatusGroup.DELIVERED,
  [Status.CANCELED]: StatusGroup.CANCELED,
}

class CustomerServiceStore {
  @observable orderValidations?: OrderValidation[]
  @observable customerScripts?: Record<string, ScriptCollection>
  @observable failedRequestedProducts?: Record<string, Record<string, any>>
  @observable requestedProductsInventoryInfo?: Record<
    string,
    Record<string, any>
  >

  constructor() {
    makeObservable(this)
  }

  // Returns a mapping from order group to order.
  @computed get orders(): Record<string, OrderPreview[]> {
    return {
      ...groupBy(OrdersStore.entityList, (order) =>
        // Move scheduled orders after today into a different group.
        isUpcomingScheduledOrder(order)
          ? StatusGroup.UPCOMING
          : StatusToGroup[order.status]
      ),
      [StatusGroup.NEEDS_SIGNATURE]: OrdersStore.needsSignatureOrders,
    }
  }

  @computed get selectedOrderId(): string | undefined {
    return OrdersStore.selectedEntityId
  }

  @computed get orderDetails(): Order | undefined {
    const order = OrdersStore.selectedEntity
    if (!order) {
      return undefined
    }

    return {
      ...order,
      // Fall back to loading the recipient from the contact store.
      recipient:
        order.recipient ??
        (order.referringPhysicianId
          ? ContactStore.physicians.find(
              (contact) => contact.id === order.referringPhysicianId
            )
          : undefined),
    }

    // Optimization: if it's already in the entity list, grab the preview.
    // TODO: this breaks a bunch of types and requires default values everywhere.
    /*
      OrdersStore.entityList.find(
        (entity) => entity.id === OrdersStore.selectedEntityId
      )*/
  }

  @computed get loadingOrders(): boolean {
    return OrdersStore.loadingEntities
  }

  @computed get loadingOrderDetails(): boolean {
    return OrdersStore.loadingOrderDetails
  }

  @computed get orderPriority(): string | undefined {
    return OrdersStore.orderPriority
  }

  // TODO(ivan): Simplify a lot of these computed checks which are redundant.
  @computed get loadingOrderValidations(): boolean {
    return (
      !isUndefined(OrdersStore.selectedEntityId) &&
      (isUndefined(this.orderValidations) ||
        isUndefined(OrdersStore.selectedEntity))
    )
  }

  @computed get loadingCustomerScripts(): boolean {
    return (
      !isUndefined(OrdersStore.selectedEntityId) &&
      (isUndefined(this.customerScripts) ||
        isUndefined(OrdersStore.selectedEntity))
    )
  }

  @computed get requestedProductsApproved(): boolean {
    return (
      !isUndefined(OrdersStore.selectedEntityId) &&
      !this.loadingOrderValidations &&
      flatten(values(this.failedRequestedProducts)).length === 0
    )
  }

  @computed get selectedOrderErrors(): SyncErrors | undefined {
    return OrdersStore.selectedEntityId !== undefined
      ? OrdersStore.entityErrors[OrdersStore.selectedEntityId]
      : undefined
  }

  @action.bound startPollingOrders(): void {
    OrdersStore.startPolling()
  }

  @action.bound stopPollingOrders(): void {
    OrdersStore.stopPolling()
  }

  @action.bound clearOrderValidations(): void {
    this.orderValidations = undefined
  }

  @action.bound clearCustomerScripts(): void {
    this.customerScripts = undefined
  }

  @action.bound async refreshOrder(): Promise<void> {
    if (OrdersStore.selectedEntityId) {
      this.selectOrder(OrdersStore.selectedEntityId, true)
    }
  }

  @action.bound async selectOrder(
    orderId?: string,
    refresh = false
  ): Promise<void> {
    // prevent querying what we're already looking at
    if (!refresh && orderId === OrdersStore.selectedEntityId) {
      return
    }

    await OrdersStore.selectOrder(orderId, this.clearOrderValidations, refresh)
  }

  @action.bound async searchOrder(query: string): Promise<void> {
    const orderId = query.trim()
    await OrdersStore.searchOrder(orderId, this.clearOrderValidations)
    if (orderId) {
      ViewStore.showView(ViewId.CUSTOMER_SERVICE, { orderId })
    } else {
      ViewStore.showView(ViewId.CUSTOMER_SERVICE, {})
    }
  }

  @action.bound async validateOrder(orderId: string) {
    const validations = await OrderService.validateOrder(orderId)
    const customerScripts = await OrderService.getScriptsForOrder(orderId)

    runInAction(() => {
      this.orderValidations = validations.validations
      this.customerScripts = customerScripts
      this.failedRequestedProducts = validations.errors.requested_products
      this.requestedProductsInventoryInfo =
        validations.extra.requested_products_inventory
    })
    return validations
  }

  @action.bound async updateOrder(
    updatedOrder: Order,
    waitForValidation = false
  ): Promise<Order> {
    OrdersStore.update(updatedOrder)
    const validation = this.validateOrder(updatedOrder.id)
    if (waitForValidation) {
      await validation
    }
    return updatedOrder
  }

  @action.bound async updateOrderApproval(approver: string) {
    if (!OrdersStore.selectedEntity) {
      throw 'No current order!'
    }

    await OrderService.updateOrderApproval(
      OrdersStore.selectedEntity.id,
      approver
    )
    const validations = await this.validateOrder(OrdersStore.selectedEntity.id)
    return validations
  }

  @action.bound async removeOrderApproval() {
    if (!OrdersStore.selectedEntity) {
      throw 'No current order!'
    }

    await OrderService.removeOrderApproval(OrdersStore.selectedEntity.id)
    const validations = await this.validateOrder(OrdersStore.selectedEntity.id)
    return validations
  }

  @action.bound async confirmOrder(orderId: string): Promise<void> {
    const order = {
      ...OrdersStore.detailedEntities[orderId],
      timeCustomerNotifiedConfirmed: new Date(Date.now()).toISOString(),
    }
    await this.updateOrder(order, true)
  }

  @action.bound async unconfirmOrder(orderId: string): Promise<void> {
    const order = {
      ...OrdersStore.detailedEntities[orderId],
      timeCustomerNotifiedConfirmed: null,
    }
    await this.updateOrder(order, true)
  }

  @action.bound async confirmOrderOutOfStock(orderId: string): Promise<void> {
    const order = {
      ...OrdersStore.detailedEntities[orderId],
      timeCustomerNotifiedOutOfStock: new Date(Date.now()).toISOString(),
    }
    await this.updateOrder(order, true)
  }

  @action.bound async unconfirmOrderOutOfStock(orderId: string): Promise<void> {
    const order = {
      ...OrdersStore.detailedEntities[orderId],
      timeCustomerNotifiedOutOfStock: null,
    }
    order.timeCustomerNotifiedOutOfStock = null
    await this.updateOrder(order, true)
  }

  @action.bound async confirmCreditCheckFailed(orderId: string): Promise<void> {
    const order = {
      ...OrdersStore.detailedEntities[orderId],
      timeCustomerNotifiedCreditCheckFailed: new Date(Date.now()).toISOString(),
    }
    await this.updateOrder(order, true)
  }

  @action.bound async unconfirmCreditCheckFailed(
    orderId: string
  ): Promise<void> {
    const order = {
      ...OrdersStore.detailedEntities[orderId],
      timeCustomerNotifiedCreditCheckFailed: null,
    }
    await this.updateOrder(order, true)
  }
}

export default new CustomerServiceStore()
