/** Waits for a service worker to finish installing and triggers a callback. */
export function listenForWaitingServiceWorker(
  reg: ServiceWorkerRegistration,
  callback: (reg: ServiceWorkerRegistration) => void
): void {
  if (!reg) return
  if (reg.waiting) {
    return callback(reg)
  }

  function awaitStateChange() {
    reg.installing?.addEventListener('statechange', function () {
      if (this.state === 'installed') callback(reg)
    })
  }
  awaitStateChange()
  reg.addEventListener('updatefound', awaitStateChange)
}
