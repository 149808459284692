import React from 'react'

import { ExternalLink } from 'react-feather'

interface LinkProps {
  newWindow?: boolean
  icon?: React.ReactElement
}

const Link: React.FC<LinkProps & React.HTMLProps<HTMLAnchorElement>> = ({
  newWindow = false,
  icon = undefined,
  children,
  ...props
}) => {
  const linkProps = newWindow
    ? {
        target: '_blank',
        // Improve performance and security as explained in
        // https://developers.google.com/web/tools/lighthouse/audits/noopener
        rel: 'noopener noreferrer',
        ...props,
      }
    : props
  return (
    <a {...linkProps}>
      {children}
      {icon ||
        (newWindow && <ExternalLink size={16} className="text-primary ml-2" />)}
    </a>
  )
}

export default Link
