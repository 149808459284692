import axios from 'axios'
import mapValues from 'lodash/mapValues'

import { PackageType } from 'src/models/nest'
import {
  BasicPackage,
  Package,
  PackageMeta,
  Reminder,
} from 'src/models/package'
import { PrinterLabel } from 'src/models/printer_label'
import { ScriptCollection } from 'src/models/script'
import { transformBasicPackage, transformPackage } from 'src/utils/transformers'

import client from './client'
import { downloadFile, getFilename } from './utils'

export enum FlightStatus {
  LAUNCHED = 'launched',
  APPROACHING = 'approaching',
  DELIVERED = 'delivered',
  FAILED = 'failed',
}

export async function getPackage(
  packagePk: number
): Promise<BasicPackage | undefined> {
  try {
    const response = await client.get(`/api/internal/packages/${packagePk}/`)
    return transformBasicPackage(response.data)
  } catch (e) {
    // Expect 404s in case the wrong id is provided.
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return undefined
    }
    throw e
  }
}

export async function getScriptsForPackage(
  packagePk: number
): Promise<Record<string, ScriptCollection>> {
  const response = await client.get(
    `/api/customerscript/packages/${packagePk}/`
  )
  return response.data
}

export function getPackageStatus(
  currentPackage: PackageMeta | undefined
): FlightStatus | undefined {
  if (!currentPackage) {
    return undefined
  }

  if (currentPackage.timeMissionFailure || currentPackage.timeFlightFailure) {
    return FlightStatus.FAILED
  } else if (currentPackage.timeDelivered) {
    return FlightStatus.DELIVERED
  } else if (currentPackage.timeApproachingDelivery) {
    return FlightStatus.APPROACHING
  } else if (currentPackage.timeLaunched) {
    return FlightStatus.LAUNCHED
  }
  return undefined
}

/** Returns whether the current package can be updated to a given status. */
export function isStatusUpdateValid(
  currentPackage: PackageMeta,
  status: FlightStatus
): boolean {
  if (!currentPackage) {
    return false
  }

  switch (status) {
    case FlightStatus.LAUNCHED:
      return !currentPackage.timeLaunched
    case FlightStatus.APPROACHING:
      return !currentPackage.timeApproachingDelivery
    case FlightStatus.DELIVERED:
      return !currentPackage.timeDelivered
    case FlightStatus.FAILED:
      return (
        !currentPackage.timeMissionFailure && !currentPackage.timeFlightFailure
      )
  }
  return true
}

export async function getPackageForZip(
  zipId: string
): Promise<PackageMeta | undefined> {
  try {
    const response = await client.get(
      `/api/internal/flight_assignment/zip/?zip_number=${zipId}`
    )

    // transform the package meta response into the app's object
    const packageMeta = response.data
    return {
      id: packageMeta.id,
      packageId: packageMeta.package_id,
      orderId: packageMeta.orders[0],
      deliverySiteName: packageMeta.delivery_site.name,
      facilityName: packageMeta.facility.name,
      facilityNotes: packageMeta.facility.notes,
      timeAssignedFlightId: packageMeta.time_assigned_flight_id,
      timeLaunched: packageMeta.time_launched,
      timeApproachingDelivery: packageMeta.time_approaching_delivery,
      timeDelivered: packageMeta.time_delivered,
      estimatedTimeToDelivery: packageMeta.estimated_time_to_delivery,
    }
  } catch (e) {
    // Expect 404s in case the wrong id is provided.
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      return undefined
    }
    throw e
  }
}

export async function deliverPackage(
  packagePk: number,
  timeDelivered: string,
  isGround: boolean,
  zipNumber?: number,
  flightId?: number
): Promise<void> {
  const payload = {
    time_delivered: timeDelivered,
    zip_number: zipNumber,
    flight_id: flightId,
    is_ground: isGround,
  }
  await client.patch(`/api/internal/packages/${packagePk}/deliver/`, payload)
}

export async function markPackageAsFailed(packagePk: number): Promise<void> {
  await client.patch(`/api/internal/packages/${packagePk}/fail/`)
}

export async function unpackPackage(
  packagePk: number
): Promise<string | undefined> {
  const response = await client.patch(
    `/api/internal/packages/${packagePk}/unpack/`
  )
  return response.data?.id
}

export async function cancelPackage(packagePk: number): Promise<void> {
  await client.patch(`/api/internal/packages/${packagePk}/cancel/`)
}

export type UnitErrors = Record<
  string,
  {
    error: string
    canOverride: boolean
  }[]
>

export async function validateCandidatePackage(
  shipmentId: string,
  packageId: string,
  units: string[],
  comments?: string
): Promise<
  | { packageId?: string[]; unitErrors?: UnitErrors; unitWarnings?: UnitErrors }
  | undefined
> {
  const payload = {
    package_id: packageId,
    shipment: shipmentId,
    units,
    comments,
  }

  const response = await client.post(
    'api/internal/packages/validate/',
    payload,
    { validateStatus: (s) => s < 500 }
  )

  if (response.status >= 200 && response.status < 300) {
    // 2xx: normal
    return undefined
  } else if (response.status >= 400) {
    // 4xx: validation error, has validation messages
    const mapErrors = (unitErrors?: any): UnitErrors =>
      mapValues(unitErrors ?? {}, (errors) =>
        errors.map(
          ({ error, canOverride }: { error: string; canOverride: string }) => ({
            error,
            // Unfortunately, errors are serialized as a string.
            canOverride: canOverride === 'True',
          })
        )
      )
    const packageId = response.data?.package_id?.join(', ')
    return {
      packageId: packageId,
      unitErrors: mapErrors(response.data.units?.errors),
      unitWarnings: mapErrors(response.data.units?.warnings),
    }
  } else {
    // other: unexpected
    throw response.data
  }
}

export async function createPackageForShipment(
  shipmentId: string,
  packageId: string,
  units: string[],
  packageType: PackageType,
  comments?: string
): Promise<Package> {
  const payload = {
    package_id: packageId,
    shipment: shipmentId,
    package_type: packageType,
    units,
    comments,
  }

  const response = await client.post(
    'api/internal/packages/assign_shipment/',
    payload
  )

  return transformPackage(response.data)
}

const FLIGHT_STATUS_TO_TIMESTAMP_FIELD: Record<FlightStatus, string> = {
  [FlightStatus.LAUNCHED]: 'time_launched',
  [FlightStatus.APPROACHING]: 'time_approaching_delivery',
  [FlightStatus.DELIVERED]: 'time_delivered',
  [FlightStatus.FAILED]: 'time_mission_failure',
}

export async function resetPackageTimestamps(
  packageId: number,
  flightStatuses: FlightStatus[]
) {
  const payload = flightStatuses.reduce(
    (acc: Record<string, null>, flightStatus) => {
      const timestampField = FLIGHT_STATUS_TO_TIMESTAMP_FIELD[flightStatus]
      acc[timestampField] = null
      return acc
    },
    {}
  )
  return modifyPackageMeta(packageId, payload)
}

export async function updatePackageTimestamp(
  packagePk: number,
  flightStatus: FlightStatus
): Promise<void> {
  const timestampField = FLIGHT_STATUS_TO_TIMESTAMP_FIELD[flightStatus]
  const payload = { [timestampField]: new Date(Date.now()).toISOString() }
  return modifyPackageMeta(packagePk, payload)
}

const FLIGHT_STATUS_TO_NOTIFICATION_FIELD: Record<FlightStatus, string> = {
  [FlightStatus.LAUNCHED]: 'time_customer_notified_launched',
  [FlightStatus.APPROACHING]: 'time_customer_notified_approaching_delivery',
  [FlightStatus.DELIVERED]: 'time_customer_notified_delivered',
  [FlightStatus.FAILED]: 'time_customer_notified_failure',
}

export async function notifyCustomer(
  packagePk: number,
  flightStatus: FlightStatus,
  reset = false
): Promise<void> {
  const timestampField = FLIGHT_STATUS_TO_NOTIFICATION_FIELD[flightStatus]
  const payload = reset
    ? { [timestampField]: null }
    : { [timestampField]: new Date(Date.now()).toISOString() }
  return modifyPackageMeta(packagePk, payload)
}

export async function updatePhysicalPackageInfo(
  packagePk: number,
  weight: number,
  packageType: PackageType
): Promise<void> {
  await client.patch(`/api/internal/packages/${packagePk}/weigh/`, {
    weight,
    package_type: packageType,
  })
}

export async function updateOverrideUnitWeight(
  packagePk: number,
  override_weight: number
): Promise<void> {
  await client.patch(
    `/api/internal/packages/${packagePk}/override_box_unit_weight/`,
    {
      override_weight,
    }
  )
}

async function modifyPackageMeta(
  packagePk: number,
  payload: { [key: string]: string | null }
): Promise<void> {
  const response = await client.patch(
    `/api/internal/packages_meta/${packagePk}/`,
    payload
  )
  return response.data
}

export enum UnitAction {
  REMOVE = 'remove',
  QUARANTINE = 'quarantine',
  PRODUCT_DELIVERY_LOSS = 'product_delivery_loss',
}

export interface PackageUnitsPayload {
  units: {
    id: string
    action: UnitAction
  }[]
}

export async function modifyPackageUnits(
  packagePk: number,
  payload: PackageUnitsPayload
) {
  const response = await client.patch(
    `/api/internal/packages/${packagePk}/units/`,
    payload
  )
  return response.data
}

export async function getUnusedPackageIds(count: number): Promise<void> {
  const response = await client.get('api/internal/package/ids/', {
    params: { count },
  })
  downloadFile(getFilename(response), response.data)
}

export async function getReminders(
  productSkus: string[],
  facilityId: number
): Promise<Reminder[]> {
  const products = productSkus.join(',')
  const response = await client.get('/api/internal/reminder/', {
    params: { products, facility_id: facilityId },
  })
  return response.data
}

export async function getPackageLabel(): Promise<PrinterLabel> {
  const response = await client.get('api/printerlabel/package_id/')
  return response.data
}

export async function getUnifiedLabel(
  packagePk: number,
  notes?: string,
  includeOrdererName?: boolean,
  isViaFacility?: boolean
): Promise<PrinterLabel> {
  const payload = {
    notes: notes || '',
    include_orderer_name: includeOrdererName || false,
    via_facility: isViaFacility || false,
  }

  const response = await client.post(
    `api/printerlabel/unified_package/${packagePk}/`,
    payload
  )
  return response.data
}
