import { useCallback, useRef } from 'react'

export interface CancelablePromise<T> {
  promise: Promise<T>
  cancel: () => void
}

/** Returns an object that exposes a cancel function with a promise. */
export const makeCancelable = <T>(
  promise: Promise<T>
): CancelablePromise<T> => {
  let hasCanceled = false

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (value) => (hasCanceled ? undefined : resolve(value)),
      (error) => (hasCanceled ? undefined : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    },
  }
}

/**
 * Given a function that returns a promise, debounces the promise to be returned
 * after wait milliseconds since the last time it was invoked (a la lodash debounce).
 * The function is memoized (i.e. never recomputed) unless deps change.
 */
export const useDebouncedPromise = <F extends (...args: any) => Promise<T>, T>(
  func: F,
  wait = 500,
  deps: React.DependencyList
): Function => {
  const promise = useRef<CancelablePromise<void> | undefined>(undefined)

  const debounced = (...args: Parameters<F>): Promise<T> => {
    if (promise.current) {
      promise.current.cancel()
    }

    promise.current = makeCancelable(
      new Promise<void>((resolve) => setTimeout(() => resolve(), wait))
    )
    // Chain the promise so anything after the timeout is cancelable.
    return promise.current.promise.then(() => func(...args))
  }
  return useCallback(debounced, deps)
}

/** Returns a promise that resolves after a delay. */
export const delay = (timeoutMs: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, timeoutMs))
}
