import axios from 'axios'
import isUndefined from 'lodash/isUndefined'
import keyBy from 'lodash/keyBy'

import { ProductGroup as ProductGroupNames } from 'src/constants'
import { AttributeValue, Product, ReceiveType } from 'src/models/product'
import { ProductGroup } from 'src/models/product_group'
import { transformProduct, transformProductGroup } from 'src/utils/transformers'

import client from './client'
import { downloadFile, getFilename } from './utils'

export async function getProducts(group?: string): Promise<Product[]> {
  let results: any[] = []
  let currentPage = 1
  let hasMore = false

  do {
    const response = await client.get('api/product/', {
      params: { groups__name: group, page: currentPage },
    })

    results = [...results, ...response.data.results]
    hasMore = response.data.next !== null
    currentPage += 1
  } while (hasMore)

  return results.map(transformProduct)
}

export async function getProductGroups(): Promise<
  Record<string, ProductGroup>
> {
  const response = await client.get('api/product_group/')
  const productGroups = response.data.map(transformProductGroup)
  return keyBy(productGroups, (group) => group.name)
}

type BloodProductParamType = {
  blood_group_id: string
  component_id: string
  platelet_type?: string
}

export async function getBloodProduct(
  bloodGroupId: string,
  componentId: string,
  plateletType?: string
): Promise<Product | undefined> {
  const params: BloodProductParamType = {
    blood_group_id: bloodGroupId,
    component_id: componentId,
  }
  if (plateletType) {
    params.platelet_type = plateletType
  }

  try {
    const response = await client.get('/api/internal/product/blood/', {
      params,
    })
    return transformProduct(response.data)
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status == 404) {
      // expect this might not find anything
      return undefined
    }
    throw e
  }
}

export async function getBloodComponent(
  componentId: string
): Promise<{ component_id: string }> {
  const response = await client.get('/api/internal/blood_component/', {
    params: { component_id: componentId },
  })
  return response.data
}

export async function getProductsCsv(
  productGroup?: ProductGroupNames
): Promise<void> {
  const response = await client.get(
    `/api/internal/products_csv/${
      isUndefined(productGroup) ? '' : `${productGroup}/`
    }`
  )
  downloadFile(getFilename(response), response.data)
}

export async function createOrUpdateProductsFromCSV(
  csvFile: File,
  updateUnitPrices = false
): Promise<{ created: number; updated: number }> {
  const formData = new FormData()
  formData.append('file', csvFile)

  const response = await client.post(
    `/api/products_from_csv/?backfill_prices=${updateUnitPrices}`,
    formData
  )
  return response.data
}

export interface ProductPayload {
  attributes?: Record<string, AttributeValue>
  name?: string
  receiveType?: ReceiveType
  groups?: string[]
  substitutes?: string[]
  isActive?: boolean
}

export async function updateProduct(
  sku: string,
  data: ProductPayload
): Promise<Product> {
  const payload = {
    attributes: data.attributes,
    name: data.name,
    received_as_box: data.receiveType === ReceiveType.BOX,
    groups: data.groups,
    substitutes: data.substitutes,
    is_active: data.isActive,
  }
  const response = await client.patch(`api/product/${sku}/`, payload)
  return transformProduct(response.data)
}

export async function deleteProduct(sku: string): Promise<void> {
  await client.delete(`api/product/${sku}/`)
}
