import React, { useState } from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal, { ModalProps } from 'react-bootstrap/Modal'

import { OutlineVariant } from 'src/constants'
import { getUnusedPackageIds } from 'src/services/PackageService'

const GetPackageIdsModal: React.FC<ModalProps> = ({ show, onHide }) => {
  const [isDownloading, setDownloading] = useState(false)
  const [count, setCount] = useState(10)

  return (
    <Modal onHide={onHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Download Package IDs</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-muted">
          Download a CSV with unused package IDs, for use in printing labels.
        </p>
        <Form.Group as={Form.Row}>
          <Form.Label column sm={3}>
            Number of IDs
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              type="number"
              value={count.toString()}
              min={1}
              max={1000}
              isInvalid={count < 1}
              onChange={(event: React.ChangeEvent<any>) =>
                setCount(event.target.value)
              }
            />
            <Form.Control.Feedback type="invalid">
              Must enter a positive quantity
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant={OutlineVariant.SECONDARY} onClick={onHide}>
          Close
        </Button>
        <Button
          onClick={async () => {
            setDownloading(true)
            await getUnusedPackageIds(count)
            setDownloading(false)
          }}
          disabled={count < 1 || isDownloading}
        >
          {isDownloading ? 'Downloading...' : 'Download IDs'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default GetPackageIdsModal
