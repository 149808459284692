import React from 'react'

import styled from 'styled-components'

import {
  Notification as NotificationModel,
  NotificationType,
} from 'src/models/notification'
import NotificationsStore from 'src/stores/NotificationsStore'
import { displayRelativeTime } from 'src/utils/datetime'

interface NotificationProps {
  notification: NotificationModel
}

const StyledNotification = styled.div`
  padding: 20px 12px;
  width: 400px;

  &:hover {
    background: lightsteelblue;
    cursor: pointer;
  }
`

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const notificationClass =
    notification.notificationType === NotificationType.TIME_BEGIN_PACKING ||
    notification.notificationType === NotificationType.NEW_ORDER
      ? 'alert-danger'
      : 'alert-info'
  return (
    <StyledNotification
      className={notificationClass}
      onClick={() => NotificationsStore.navigate(notification)}
    >
      <h6 className="text-sm text-muted text-uppercase">
        {displayRelativeTime(notification.timestamp)}
      </h6>
      <div>{notification.message}</div>
    </StyledNotification>
  )
}

export default Notification
