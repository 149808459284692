import { computed, makeObservable } from 'mobx'

import Contact, { ContactType } from 'src/models/contact'
import * as ContactService from 'src/services/ContactService'
import LocalCacheStore from 'src/stores/LocalCacheStore'

class ContactStore extends LocalCacheStore<Contact, Contact> {
  constructor() {
    super()
    makeObservable(this)
  }

  get properties(): (keyof this)[] {
    return ['entityList']
  }

  @computed get contacts(): Contact[] {
    // The first time this is computed, lazily fetch from the server.
    if (this.loadingEntities) {
      this.refreshAll()
    }
    return this.entityList
  }

  @computed get physicians(): Contact[] {
    return this.contacts.filter(
      (contact) => contact.contactType === ContactType.PHYSICIAN
    )
  }

  // Implementation of abstract methods.
  protected refreshEntityList(): Promise<Contact[]> {
    return ContactService.getContacts()
  }

  protected getEntity(entityId: number): Promise<Contact> {
    throw Error('not supported')
  }

  protected createEntity(contact: Contact): Promise<Contact> {
    return ContactService.createContact(contact)
  }

  protected updateEntity(contact: Contact): Promise<Contact> {
    return ContactService.updateContact(contact)
  }

  protected deleteEntity(contact: Contact): Promise<Contact> {
    throw Error('not supported')
  }
}

export default new ContactStore()
