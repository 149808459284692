import { ProductGroup } from 'src/constants'

export enum AttributeType {
  DATE = 'date',
  FLOAT = 'float',
  INTEGER = 'integer',
  STRING = 'string',
  BOOLEAN = 'boolean',
}

export enum ReceiveType {
  UNIT = 'Unit',
  BOX = 'Box',
}

export enum AttributeRole {
  DISPLAY_IN_FILTER = 'display_in_filter',
  DISPLAY_IN_LIST = 'display_in_list',
  DISPLAY_IN_PACKING = 'display_in_packing',
  DISPLAY_IN_VIEW = 'display_in_view',
}

export type AttributeValue = any

export interface Attribute {
  name: string
  type: AttributeType
  value: AttributeValue
  displayName: string
  changeable: boolean
  roles: AttributeRole[]
}

export interface FilterableAttribute extends Attribute {
  possibleValues: AttributeValue[]
}

export type AttributeMap = Record<string, Attribute>

export interface BasicProduct {
  id: string
  sku: string
  name: string
  groups: ProductGroup[]
  attributes?: AttributeMap
}

export interface Product extends Omit<BasicProduct, 'attributes'> {
  attributes: AttributeMap // required
  requestedProductAttributes: AttributeMap
  unitAttributes: AttributeMap
  substitutes: string[]
  receiveType: ReceiveType
  isActive: boolean
}

export enum PlateletType {
  WHOLE_BLOOD_DERIVED = 'Whole blood-derived',
  APHEREISIS = 'Apheresis',
}
