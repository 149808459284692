import { action, computed, observable, runInAction } from 'mobx'

import { AuthUser, UserGroupType } from 'src/models/users'
import * as AuthenticationService from 'src/services/AuthenticationService'

class AuthStore {
  @observable currentUser?: AuthUser
  @observable authError?: string = ''

  @computed get isPrivilegedUser(): boolean {
    if (!this.currentUser) {
      return false
    }
    const isLead = this.currentUser?.groups?.includes(
      UserGroupType.FULFILLMENT_LEAD
    )
    const isSuperuser = this.currentUser?.isSuperuser
    return isLead || isSuperuser
  }

  @action async logout() {
    await AuthenticationService.logoutUser()
    runInAction(() => {
      this.currentUser = undefined
    })
  }

  @action async getCurrentUser(): Promise<AuthUser | undefined> {
    const user = await AuthenticationService.getUser()
    runInAction(() => {
      if (user) {
        this.currentUser = user
      }
    })
    return user
  }

  @action.bound setCurrentUser(user?: AuthUser) {
    this.currentUser = user
    return user
  }

  @action.bound setAuthError(errorMsg: string) {
    this.authError = errorMsg
  }

  @action.bound clearAuthError() {
    this.authError = ''
  }
}

export default new AuthStore()
