import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup, {
  ToggleButtonRadioProps,
} from 'react-bootstrap/ToggleButtonGroup'

import { OutlineVariant, ProductGroup } from 'src/constants'
import ProductStore from 'src/stores/ProductStore'

interface ProductGroupToggleProps {
  name: string
  value?: ProductGroup
  onChange?: (value: ProductGroup) => void
  className?: string
}

const ProductGroupToggle: React.FC<
  ProductGroupToggleProps & ToggleButtonRadioProps<ProductGroup>
> = observer(({ name, value, onChange, ...props }) => {
  const outlineVariant = ((currentGroup) => {
    switch (currentGroup) {
      case ProductGroup.BLOOD:
        return OutlineVariant.DANGER

      case ProductGroup.MEDICAL:
        return OutlineVariant.PRIMARY

      case ProductGroup.VACCINE:
        return OutlineVariant.INFO

      case ProductGroup.SAMPLE:
        return OutlineVariant.SUCCESS

      case ProductGroup.ANIMAL_CRYONICS:
        return OutlineVariant.WARNING

      case ProductGroup.MERCHANDISE:
      case ProductGroup.INDUSTRIAL_PARTS:
      case ProductGroup.CROSSDOCK:
      case ProductGroup.SWAG:
      case ProductGroup.EDUCATION:
      case ProductGroup.FAMILY_PLANNING:
      case ProductGroup.NUTRITION:
      case ProductGroup.WATER_AND_SANITARY:
        return OutlineVariant.SECONDARY

      default:
        return OutlineVariant.SECONDARY
    }
  })(value)

  const orderedGroups = [
    ProductGroup.BLOOD,
    ProductGroup.MEDICAL,
    ProductGroup.VACCINE,
    ProductGroup.SAMPLE,
    ProductGroup.SWAG,
    ProductGroup.CONSUMABLE,
    ProductGroup.CROSSDOCK,
    ProductGroup.ANIMAL_CRYONICS,
    ProductGroup.MERCHANDISE,
    ProductGroup.INDUSTRIAL_PARTS,
    ProductGroup.EDUCATION,
    ProductGroup.FAMILY_PLANNING,
    ProductGroup.NUTRITION,
    ProductGroup.WATER_AND_SANITARY,
  ]

  useEffect(() => {
    ProductStore.getProductGroups()
  }, [])

  return (
    <ToggleButtonGroup
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    >
      {orderedGroups.map(
        (group) =>
          ProductStore.groupsWithProducts.includes(group) && (
            <ToggleButton key={group} value={group} variant={outlineVariant}>
              {group}
            </ToggleButton>
          )
      )}
    </ToggleButtonGroup>
  )
})

export default ProductGroupToggle
