/* Keep this in sync with common.services.notifications_service */
export enum NotificationType {
  OTHER = 0,
  TIME_BEGIN_PACKING = 1,
  PACKAGE_LAUNCHED = 2,
  PACKAGE_APPROACHING = 3,
  PACKAGE_DELIVERED = 4,
  PACKAGE_FAILURE = 5,
  NEW_ORDER = 6,
}

export interface Notification {
  id: string
  entityId: string
  notificationType: NotificationType
  message: string
  timestamp: string
  metadata?: {
    orderId: string
    packageId?: string
  }
}
