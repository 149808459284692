import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import isUndefined from 'lodash/isUndefined'
import pickBy from 'lodash/pickBy'

import { TIME_INTERVAL_FOR_SAFETY_CHECK_MS } from 'src/constants'
import { SafetyCheckStatus } from 'src/models/sites'

export function downloadFile(filename: string, data: string): void {
  const encodedUri = encodeURI(data)
  const link = document.createElement('a')
  link.href = `data:text/csv;charset=utf-8,${encodedUri}`
  link.target = '_blank'
  link.download = filename
  document.body.appendChild(link) // Required for FF
  link.click()
  document.body.removeChild(link) // Required for FF
}

export function getFilename(response: AxiosResponse): string {
  return response.headers['content-disposition']
    .replace('attachment; filename="', '')
    .replace('"', '')
}

export function removeBlanks(values: Record<string, any>): Record<string, any> {
  return pickBy(values, (val) => !isUndefined(val) && val !== '')
}

export function isUnsafe(safetyCheckStatus?: SafetyCheckStatus): boolean {
  return safetyCheckStatus === SafetyCheckStatus.FAIL
}

export function isDueForSafetyCheck(timeLastSafetyCheck?: string): boolean {
  let shouldCheck = true
  if (timeLastSafetyCheck) {
    const today = dayjs()
    const cutoff = dayjs(timeLastSafetyCheck).add(
      TIME_INTERVAL_FOR_SAFETY_CHECK_MS,
      'millisecond'
    )
    shouldCheck = today.isAfter(cutoff)
  }
  return shouldCheck
}

export function requiresSafetyCheck(
  safetyCheckStatus?: SafetyCheckStatus,
  timeLastSafetyCheck?: string
): boolean {
  return isUnsafe(safetyCheckStatus) || isDueForSafetyCheck(timeLastSafetyCheck)
}

export function snakeToTitle(value: string): string {
  return value
    .split('_')
    .map((word) => word.replace(word[0], word.charAt(0).toUpperCase()))
    .join(' ')
}

export function camelToTitle(value: string): string {
  const result = value.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}
