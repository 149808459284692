import React from 'react'

import BootstrapSpinner from 'react-bootstrap/Spinner'

interface SpinnerProps {
  animation?: 'border' | 'grow'
  size?: 'sm'
}

const Spinner: React.FC<
  SpinnerProps & Omit<React.HTMLProps<HTMLDivElement>, 'size'>
> = ({ animation, children, size, className, ...props }) => {
  return (
    <div className={`d-flex align-items-center ${className}`} {...props}>
      <BootstrapSpinner
        className="mr-2"
        animation={animation || 'border'}
        size={size}
      />
      {children}
    </div>
  )
}

export default Spinner
