import axios from 'axios'
import isUndefined from 'lodash/isUndefined'
import mapValues from 'lodash/mapValues'

import { ProductGroup, ReturnReason } from 'src/constants'
import { PrinterLabel } from 'src/models/printer_label'
import { AttributeMap, AttributeValue, ReceiveType } from 'src/models/product'
import {
  ReceivedShipment,
  ReceivedShipmentSummary,
  ReceivedShipmentValues,
  ReturnedShipment,
  ReturnedShipmentSummary,
} from 'src/models/supplier_shipment'
import {
  BasicUnitWithProduct,
  BasicUnitWithReturnDate,
  Unit,
  UnitAction,
  UnitBox,
  UnitStatus,
} from 'src/models/unit'
import {
  transformBasicUnitWithProduct,
  transformBasicUnitWithReturnDate,
  transformReceivedShipment,
  transformReceivedShipmentSummary,
  transformReturnedShipment,
  transformReturnedShipmentSummary,
  transformUnit,
  transformUnitAction,
  transformUnitBox,
  transformUnitStatus,
} from 'src/utils/transformers'

import client from './client'
import { downloadFile, getFilename, removeBlanks } from './utils'

export interface UnitsSearchParams {
  page: number
  pageSize: number
  unit_ids?: string[]
  [key: string]: any
}

export async function getUnitActions(): Promise<UnitAction[]> {
  const response = await client.get('api/internal/units/actions/')
  return response.data.map(transformUnitAction)
}

export async function getUnitStatuses(): Promise<UnitStatus[]> {
  const response = await client.get('api/internal/units/choices/status/')
  return response.data.map(transformUnitStatus)
}

export async function getUnitDerivedStatuses(): Promise<UnitStatus[]> {
  const response = await client.get(
    'api/internal/units/choices/derived_status/'
  )
  return response.data.map(transformUnitStatus)
}

export async function getReceivedShipmentSummary(
  receivedShipment: ReceivedShipmentValues
): Promise<ReceivedShipmentSummary[]> {
  const response = await client.get('api/internal/received_shipments/', {
    params: {
      shipment_id: receivedShipment.shipmentId!,
      supplier_name: receivedShipment.supplier!.name,
    },
  })
  return response.data.map(transformReceivedShipmentSummary)
}

export async function getReceivedShipments(): Promise<ReceivedShipment[]> {
  const response = await client.get('api/internal/received_shipments/')
  return response.data.map(transformReceivedShipment)
}

function transformReceivedShipmentPayload(
  receivedShipment: ReceivedShipmentValues
) {
  return removeBlanks({
    shipment_id: receivedShipment?.shipmentId,
    supplier: receivedShipment?.supplier?.name,
    received_nest_id: receivedShipment?.nestId ?? undefined,
    supplier_order_id: receivedShipment?.supplierOrderId ?? undefined,
  })
}

interface CreateUnitData {
  id?: string
  productSku: string
  receivedShipment: ReceivedShipmentValues
  attributes: Record<string, AttributeValue>
  restrictedFacilityGroup?: number
  orderId?: string
  quantity?: number
  vvmQuantity?: Record<number, number>
}

const createBaseUnitPayload = (unitData: CreateUnitData) => ({
  received_shipment: transformReceivedShipmentPayload(
    unitData.receivedShipment
  ),
  id: unitData.id,
  product: unitData.productSku,
  attributes: mapValues(unitData.attributes, (value, name) => ({
    name,
    value,
  })),
  order: unitData.orderId,
  restricted_facility_group: unitData.restrictedFacilityGroup,
})

export async function createUnit(
  unitData: CreateUnitData,
  receiveType: ReceiveType,
  unitType?: ProductGroup
): Promise<string> {
  const isBox = receiveType === ReceiveType.BOX
  const isVaccineBox = isBox && unitType === ProductGroup.VACCINE
  const payload = {
    ...createBaseUnitPayload(unitData),
    vvm_quantity: isVaccineBox ? unitData.vvmQuantity : undefined,
    quantity: isBox && !isVaccineBox ? unitData.quantity : undefined,
  }

  const response = isBox
    ? await client.post('api/internal/unit_boxes/', payload)
    : await client.post('api/internal/units/', payload)
  return response.data.id
}

export interface UnitPayload {
  comments?: string
  status?: string
  receivedShipment?: ReceivedShipment
  attributes?: AttributeMap
  restrictedFacilityGroup?: number
  returnedShipment?: ReturnedShipment
  returnReason?: ReturnReason | null
}

interface BulkUnitCreateResponse {
  unit_ids: string[]
}

export async function updateUnit(id: string, data: UnitPayload): Promise<Unit> {
  const payload = {
    comments: data.comments,
    status: data.status,
    received_shipment: isUndefined(data.receivedShipment)
      ? undefined
      : transformReceivedShipmentPayload(data.receivedShipment),
    attributes: data.attributes,
    restricted_facility_group: data.restrictedFacilityGroup,
    return_reason: data.returnReason,
    return_shipment: data.returnedShipment,
  }
  const response = await client.patch(`api/internal/units/${id}/`, payload)
  return transformUnit(response.data)
}

export async function removeReturnShipment(ids: Array<string>) {
  const payload = {
    ids,
  }
  await client.post(`api/internal/units/remove_return_shipment/`, payload)
}

export async function performActionOnUnits(
  actionName: string,
  reason: string,
  unitIds: string[],
  receivedShipmentId?: string,
  supplierId?: number
) {
  const payload = {
    action: actionName,
    reason,
    ids: unitIds,
    receivedShipmentId,
    supplierId,
  }

  const response = await client.post('api/internal/units/actions/', payload)
  return response.data
}

export async function updateUnitAttributes(
  unitIds: string[],
  attributes: Record<string, AttributeValue>
) {
  const payload = {
    ids: unitIds,
    attributes,
  }

  const response = await client.patch(
    'api/internal/units/edit_attributes/',
    payload
  )
  return response.data
}

export interface UnitBoxPayload {
  quantity?: number
  receivedShipment?: ReceivedShipment
  attributes?: AttributeMap
  restrictedFacilityGroup?: number
}

export async function getUnitsToPackFromBox(
  boxId: string,
  quantity: number
): Promise<Unit[]> {
  try {
    const response = await client.post(
      `api/internal/pick_from_unit_box/${boxId}/`,
      { quantity }
    )
    return response.data.map(transformUnit)
  } catch (e) {
    // Expect 400s if there's an error picking
    if (axios.isAxiosError(e) && e.response?.status === 400) {
      throw e.response.data
    }

    throw e
  }
}

export async function getUnitsToPackFromVaccineBox(
  boxId: string,
  vvmQuantity: Record<number, number>
): Promise<Unit[]> {
  try {
    const response = await client.post(
      `api/internal/pick_from_unit_box/${boxId}/`,
      { vvm_quantity: vvmQuantity }
    )

    return response.data.map(transformUnit)
  } catch (e) {
    // Expect 400s if there's an error picking
    if (axios.isAxiosError(e) && e.response?.status === 400) {
      throw e.response.data
    }

    throw e
  }
}

export async function updateUnitBox(boxId: string, data: UnitBoxPayload) {
  const payload = {
    quantity: data.quantity,
    received_shipment: isUndefined(data.receivedShipment)
      ? undefined
      : {
          shipment_id: data.receivedShipment.shipmentId,
          supplier: {
            name: data.receivedShipment.supplier?.name,
            phone_number: data.receivedShipment.supplier?.phoneNumber,
            email: data.receivedShipment.supplier?.email,
          },
        },
    attributes: data.attributes,
    restricted_facility_group: data.restrictedFacilityGroup,
  }

  const response = await client.patch(
    `api/internal/unit_boxes/${boxId}/`,
    payload
  )
  return transformUnitBox(response.data)
}

export async function deleteUnitBox(boxId: string): Promise<void> {
  try {
    await client.delete(`api/internal/unit_boxes/${boxId}/`)
  } catch (e) {
    // Expect 400s in case the box cannot be deleted.
    if (axios.isAxiosError(e) && e.response?.status === 400) {
      throw e.response.data.error
    }
    throw e
  }
}

export async function searchUnits({
  unit_ids,
  ...params
}: UnitsSearchParams): Promise<{
  count: number
  units: BasicUnitWithProduct[]
}> {
  const response = await client.post(
    'api/internal/units/search/',
    { unit_ids }, // Send unit IDs in data of post request.
    { params } // Send remaining params in url query params.
  )
  return {
    count: response.data.count,
    units: response.data.results.map(transformBasicUnitWithProduct),
  }
}

export async function getUnit(unitId: string): Promise<Unit> {
  const response = await client.get(`api/internal/units/${unitId}/`)
  return transformUnit(response.data)
}

export async function getUnitBox(boxId: string): Promise<UnitBox> {
  const response = await client.get(`api/internal/unit_boxes/${boxId}/`)
  return transformUnitBox(response.data)
}

export async function getUnitOrBox(
  id: string
): Promise<{ isBox: boolean; data: Unit | UnitBox }> {
  const response = await client.get(`api/internal/unit_or_box/${id}/`)
  const isBox = response.data.is_box

  return {
    isBox,
    data: isBox
      ? transformUnitBox(response.data.data)
      : transformUnit(response.data.data),
  }
}

export async function getUnusedUnitIds(
  productGroup: string,
  count: number
): Promise<void> {
  const response = await client.get(`api/internal/units/ids/${productGroup}/`, {
    params: { count },
  })
  downloadFile(getFilename(response), response.data)
}

interface UnitIdLabelResponse {
  printOptions: PrinterLabel
  unitIds: string[]
}

export async function getUnitIdLabels(
  productGroupName: string,
  count: number
): Promise<UnitIdLabelResponse> {
  const response = await client.get(
    `api/printerlabel/unit_id_labels/${productGroupName}/`,
    {
      params: { count },
    }
  )
  return response.data
}

/** Get a single ZPL file with all unit ID labels in it. */
export async function getBulkUnitIdLabels(
  unitIds: string[]
): Promise<PrinterLabel> {
  const response = await client.post(`api/printerlabel/bulk_unit_id_labels/`, {
    unit_ids: unitIds,
  })
  return response.data
}

export async function unreserveUnit(id: string): Promise<void> {
  await client.patch(`api/internal/units/${id}/unreserve/`)
}

export type ExpiringReturnableUnits = BasicUnitWithReturnDate[]

export async function findUnitsForReturnedShipment(
  receivedShipmentId: number
): Promise<ExpiringReturnableUnits> {
  const response = await client.get(
    `api/internal/returned_shipments/${receivedShipmentId}/find_units/`
  )
  return response.data.map(transformBasicUnitWithReturnDate)
}

export async function createReturnedShipment(
  supplier: string,
  timeReturn: string,
  timeNextReturn: string
): Promise<ReturnedShipmentSummary> {
  const response = await client.post('api/internal/returned_shipments/', {
    supplier,
    time_return: timeReturn,
    time_next_return: timeNextReturn,
  })
  return transformReturnedShipmentSummary(response.data)
}

export async function updateReturnedShipmentUnits(
  returnedShipmentId: number,
  unitIds: string[]
): Promise<ReturnedShipment> {
  const response = await client.patch(
    `api/internal/returned_shipments/${returnedShipmentId}/`,
    {
      units: unitIds,
    }
  )
  return transformReturnedShipment(response.data)
}

export async function getReturnedShipments(
  supplier?: string
): Promise<ReturnedShipment[]> {
  const response = await client.get('api/internal/returned_shipments/', {
    params: removeBlanks({
      supplier_name: supplier,
    }),
  })
  return response.data.map(transformReturnedShipment)
}

export async function getReturnedShipment(
  returnedShipmentId: number
): Promise<ReturnedShipmentSummary> {
  const response = await client.get(
    `api/internal/returned_shipments/${returnedShipmentId}/`
  )
  return transformReturnedShipmentSummary(response.data)
}

export async function getReturnReceipt(
  receivedShipmentId: number
): Promise<ReturnedShipmentSummary> {
  const response = await client.get(
    `api/internal/returned_shipments/${receivedShipmentId}/return_receipt/`
  )
  return transformReturnedShipmentSummary(response.data)
}

export async function editSupplierOrderId(
  shipmentId: string,
  supplierName: string,
  supplierOrderId: string,
  unitIds: string[],
  overwriteSupplierOrderId?: boolean
): Promise<void> {
  const payload = {
    shipment_id: shipmentId,
    supplier_name: supplierName,
    supplier_order_id: supplierOrderId,
    unit_ids: unitIds,
    overwrite_supplier_order_id: overwriteSupplierOrderId,
  }
  const response = await client.post(
    'api/internal/units/edit_supplier_order_id/',
    payload
  )
  return response.data
}

export async function getTotalUnitWeight(unitIds: string[]): Promise<number> {
  const payload = {
    unit_ids: unitIds,
  }
  const response = await client.post(
    'api/internal/units/actions/total_weight/',
    payload
  )
  return response.data['total']
}

export async function bulkEditUnitProduct(
  unitIds: string[],
  currentProduct: string,
  newProduct: string,
  attributes?: Record<string, any>
): Promise<void> {
  const payload = {
    unit_ids: unitIds,
    current_product: currentProduct,
    new_product: newProduct,
    attributes: attributes,
  }
  const response = await client.post(
    'api/internal/units/bulk_edit_unit_product/',
    payload
  )
  return response.data
}

export async function createUnitsBulk(
  unitData: CreateUnitData,
  count: number
): Promise<BulkUnitCreateResponse> {
  const payload = {
    validated_data: createBaseUnitPayload(unitData),
    count: count,
  }

  const response = await client.post('api/internal/units/bulk_create/', payload)
  return response.data
}
