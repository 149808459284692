import React, { useRef, useState } from 'react'

import Overlay from 'react-bootstrap/Overlay'
import { Icon as IconType, Info } from 'react-feather'

import { UpdatingTooltip } from 'src/components/Tooltip'

interface InfoBadgeProps {
  id: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  icon?: IconType
  color?: string
  size?: number
  className?: string
}

const InfoBadge: React.FC<React.PropsWithChildren<InfoBadgeProps>> = ({
  placement = 'top',
  icon,
  color = 'var(--info)',
  size,
  className,
  children,
  ...props
}) => {
  const Icon = icon ?? Info
  const content = useRef<HTMLSpanElement>(null)
  const [show, setShow] = useState<boolean>(false)

  return (
    <>
      <span
        ref={content}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <Icon size={size} color={color} className={className} />
      </span>
      <Overlay target={content} placement={placement} show={show}>
        <UpdatingTooltip {...props}>{children}</UpdatingTooltip>
      </Overlay>
    </>
  )
}

export default InfoBadge
