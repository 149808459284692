import {
  AlertTriangle,
  Archive,
  Box,
  Check,
  Clock,
  CloudOff,
  CreditCard,
  Edit,
  Flag,
  MessageCircle,
  PlusCircle,
  Send,
  ShoppingCart,
  StopCircle,
  Truck,
  XCircle,
} from 'react-feather'

import { NotificationType } from 'src/models/notification'
import { Status as OrderStatus, Priority } from 'src/models/order'
import { Status as ShipmentStatus } from 'src/models/shipment'
import { FacilityStatus, FacilityStatusLabels } from 'src/models/sites'

export const DEFAULT_POLL_PERIOD_MS = 5000
export const CUSTOMER_SERVICE_VIEW_POLL_PERIOD_MS = DEFAULT_POLL_PERIOD_MS
export const PACKAGING_VIEW_POLL_PERIOD_MS = DEFAULT_POLL_PERIOD_MS
export const DRAFT_ORDER_ID = 'Draft'
export const LOCAL_CREATED_ORDER_ID_PREFIX = 'Unsynced '

/** Weights of the Robin package. */
const BOX_WEIGHT = 182
const PARACHUTE_WEIGHT = 73
const AIR_INSERTS_WEIGHT = 73
export const WRAPPING_WEIGHT = 55
export const PACKAGE_WEIGHT = BOX_WEIGHT + PARACHUTE_WEIGHT + AIR_INSERTS_WEIGHT
export const PACKAGE_WEIGHT_LIMIT = 1300

// TODO(ivan): Grab this from the database instead
export const NEST_IDS = ['rw1', 'rw2', 'gh1', 'gh2', 'gh3', 'gh4', 'gh5', 'gh6']

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
}

export enum VariantRGB {
  PRIMARY = '0,123,255',
  PRIMARY_LIGHT = '168,210,255',
  SUCCESS = '40,167,69',
  DANGER = '220,53,69',
  WARNING = '255,193,7',
}

export enum OutlineVariant {
  PRIMARY = 'outline-primary',
  SECONDARY = 'outline-secondary',
  SUCCESS = 'outline-success',
  DANGER = 'outline-danger',
  WARNING = 'outline-warning',
  INFO = 'outline-info',
  LIGHT = 'outline-light',
  DARK = 'outline-dark',
}

export enum ButtonVariant {
  LINK = 'link',
}

type OldPriority = Exclude<Priority, Priority.FAST | Priority.CUSTOM_WINDOW>

export const PRIORITIES: Record<
  OldPriority,
  { label: string; style: Variant }
> = {
  [Priority.UNKNOWN]: {
    label: '?',
    style: Variant.WARNING,
  },
  [Priority.EMERGENCY]: {
    label: 'emergency',
    style: Variant.DANGER,
  },
  // NOTE: for legacy reasons this is called "Resupply" in our codebase,
  // but labeled "Replenishment" in the frontend.
  [Priority.RESUPPLY]: {
    label: 'replenishment',
    style: Variant.SECONDARY,
  },
  [Priority.SCHEDULED]: {
    label: 'scheduled',
    style: Variant.INFO,
  },
}

// new priorities
type NewPriority = Exclude<Priority, Priority.RESUPPLY>

export const PRIORITIES_V2: Record<
  NewPriority,
  { label: string; style: Variant }
> = {
  [Priority.UNKNOWN]: {
    label: '?',
    style: Variant.WARNING,
  },
  [Priority.EMERGENCY]: {
    label: 'emergency',
    style: Variant.DANGER,
  },
  [Priority.FAST]: {
    label: 'fast',
    style: Variant.SECONDARY,
  },
  [Priority.SCHEDULED]: {
    label: 'scheduled',
    style: Variant.INFO,
  },
  [Priority.CUSTOM_WINDOW]: {
    label: 'custom window',
    style: Variant.SECONDARY,
  },
}

interface StatusIcon {
  label: string
  Icon: React.ComponentType
  variant: Variant
}

export const ORDER_STATUS: Record<OrderStatus, StatusIcon> = {
  [OrderStatus.DRAFT]: {
    label: 'Draft',
    Icon: Edit,
    variant: Variant.PRIMARY,
  },
  [OrderStatus.UNSYNCED]: {
    label: 'Waiting for sync',
    Icon: CloudOff,
    variant: Variant.INFO,
  },
  [OrderStatus.CANCELED]: {
    label: 'Canceled',
    Icon: XCircle,
    variant: Variant.DANGER,
  },
  [OrderStatus.AWAITING_CREDIT_CHECK]: {
    label: 'Awaiting credit check',
    Icon: CreditCard,
    variant: Variant.WARNING,
  },
  [OrderStatus.FAILED_CREDIT_CHECK]: {
    label: 'Failed credit check',
    Icon: CreditCard,
    variant: Variant.DANGER,
  },
  [OrderStatus.NEEDS_PAYMENT]: {
    label: 'Awaiting payment',
    Icon: ShoppingCart,
    variant: Variant.WARNING,
  },
  [OrderStatus.NEEDS_NOTIFICATION]: {
    label: 'Notify customer',
    Icon: MessageCircle,
    variant: Variant.SUCCESS,
  },
  [OrderStatus.NEEDS_CONFIRMATION]: {
    label: 'Confirm products',
    Icon: Flag,
    variant: Variant.PRIMARY,
  },
  [OrderStatus.AWAITING_RESTOCK]: {
    label: 'Awaiting restock',
    Icon: Truck,
    variant: Variant.DANGER,
  },
  [OrderStatus.CONFIRMED]: {
    label: 'Confirmed',
    Icon: Box,
    variant: Variant.SUCCESS,
  },
  [OrderStatus.DELIVERED]: {
    label: 'Delivered',
    Icon: Check,
    variant: Variant.SUCCESS,
  },
  [OrderStatus.NEEDS_RECEIPT_CONFIRMATION]: {
    label: 'Awaiting Receipt Confirmation',
    Icon: MessageCircle,
    variant: Variant.DANGER,
  },
}

export const SHIPMENT_STATUS: Record<ShipmentStatus, StatusIcon> = {
  [ShipmentStatus.CANCELED]: {
    label: 'Canceled',
    Icon: XCircle,
    variant: Variant.DANGER,
  },
  [ShipmentStatus.CANCELED_NEEDS_UNPACKING]: {
    label: 'Canceled (needs unpacking)',
    Icon: XCircle,
    variant: Variant.DANGER,
  },
  [ShipmentStatus.WAITING_FOR_PACKING]: {
    label: 'Waiting for packing',
    Icon: Box,
    variant: Variant.WARNING,
  },
  [ShipmentStatus.PACKING]: {
    label: 'Packing',
    Icon: Box,
    variant: Variant.DANGER,
  },
  [ShipmentStatus.WAITING_FOR_COMMIT]: {
    label: 'Commit package',
    Icon: Clock,
    variant: Variant.DANGER,
  },
  [ShipmentStatus.ALTERNATE_DELIVERING]: {
    label: 'Delivering via ground',
    Icon: Truck,
    variant: Variant.INFO,
  },
  [ShipmentStatus.LAUNCHING]: {
    label: 'Waiting for launch',
    Icon: Send,
    variant: Variant.INFO,
  },
  [ShipmentStatus.ENROUTE]: {
    label: 'In flight',
    Icon: Send,
    variant: Variant.PRIMARY,
  },
  [ShipmentStatus.DELIVERED]: {
    label: 'Delivered',
    Icon: Check,
    variant: Variant.SUCCESS,
  },
  [ShipmentStatus.FAILED_TO_DELIVER]: {
    label: 'Failed to deliver',
    Icon: AlertTriangle,
    variant: Variant.DANGER,
  },
}

export const FACILITY_STATUS_ICONS: Record<FacilityStatus, StatusIcon> = {
  [FacilityStatus.CREATED]: {
    label: FacilityStatusLabels[FacilityStatus.CREATED],
    Icon: PlusCircle,
    variant: Variant.PRIMARY,
  },
  [FacilityStatus.OPERATIONALIZED]: {
    label: FacilityStatusLabels[FacilityStatus.OPERATIONALIZED],
    Icon: Check,
    variant: Variant.SUCCESS,
  },
  [FacilityStatus.SUSPENDED_PARTIALLY]: {
    label: FacilityStatusLabels[FacilityStatus.SUSPENDED_PARTIALLY],
    Icon: AlertTriangle,
    variant: Variant.WARNING,
  },
  [FacilityStatus.SUSPENDED_ENTIRELY]: {
    label: FacilityStatusLabels[FacilityStatus.SUSPENDED_ENTIRELY],
    Icon: StopCircle,
    variant: Variant.WARNING,
  },
  [FacilityStatus.OBSOLETE]: {
    label: FacilityStatusLabels[FacilityStatus.OBSOLETE],
    Icon: XCircle,
    variant: Variant.DANGER,
  },
  [FacilityStatus.DEPRECATED]: {
    label: FacilityStatusLabels[FacilityStatus.DEPRECATED],
    Icon: Archive,
    variant: Variant.DARK,
  },
}

export const NOTIFICATION_LABELS: [NotificationType, string][] = [
  [NotificationType.TIME_BEGIN_PACKING, 'Time to begin packing'],
  [NotificationType.PACKAGE_LAUNCHED, 'Package launched'],
  [NotificationType.PACKAGE_APPROACHING, 'Package ETA 5 min away'],
  [NotificationType.PACKAGE_DELIVERED, 'Package delivered'],
  [NotificationType.PACKAGE_FAILURE, 'Package failure'],
]

export enum ProductGroup {
  BLOOD = 'Blood',
  MEDICAL = 'Medical',
  VACCINE = 'Vaccine',
  SAMPLE = 'Sample',
  CROSSDOCK = 'Crossdock',
  SWAG = 'Swag',
  CONSUMABLE = 'Consumable',
  ANIMAL_CRYONICS = 'Animal Cryonics',
  MERCHANDISE = 'Merchandise',
  INDUSTRIAL_PARTS = 'Industrial Parts',
  EDUCATION = 'Education',
  FAMILY_PLANNING = 'Family Planning',
  NUTRITION = 'Nutrition and Child Health',
  WATER_AND_SANITARY = 'Water and Sanitary Health',
  UNKNOWN = 'Unknown',
}

export enum AuditOption {
  AUDIT_BY_SKU = 'Audit by SKU',
  AUDIT_BY_SUPPLIER = 'Audit by Supplier',
}

export enum ZIndex {
  DROPDOWN_MENU = 999,
  MODAL_DRAG_PREVIEW = 1500,
}

export enum InventoryStatus {
  ALL = 'all',
  IN_STOCK = 'in_stock',
  NOT_IN_STOCK = 'not_in_stock',
}

export enum GroupByOption {
  FACILITY = 'Facility',
  DISTRICT = 'District',
  REGION = 'Region',
}

/** Used to filter units in unit digest. */
export enum TimeIndex {
  TIME_CREATED = 'time_created',
  TIME_DELIVERED = 'package__time_delivered',
  TIME_RETURNED_TO_SUPPLIER = 'time_status_changed__returned_to_supplier',
  TIME_TRANSFERRED_TO_NEST = 'time_status_changed__transferred_to_another_nest',
}

/** Used to filter units in product audit. */
export enum AuditUnitIndex {
  DELIVERED = 'delivered',
  IN_STOCK = 'stocked',
}

/** Ask for safety check every 3 weeks */
export const TIME_INTERVAL_FOR_SAFETY_CHECK_MS = 1000 * 7 * 24 * 60 * 60 * 3

export enum ReturnReason {
  EXPIRATION = 'approaching_expiration_date',
  REDISTRIBUTION = 'redistribution_by_the_supplier',
  MOTILITY_TEST = 'failed_motility_test_at_zipline',
  VISUAL_INSPECTION_CLOTTING = 'failed_visual_inspection_due_to_clotting',
  VISUAL_INSPECTION_HEMOLYSIS = 'failed_visual_inspection_due_to_hemolysis',
  VISUAL_INSPECTION_LEAKAGE = 'failed_visual_inspection_due_to_leakage_after_thawing',
  VISUAL_INSPECTION_HEALTH_FACILITY = 'failed_visual_inspection_at_health_facility',
  OUT_TOO_LONG = 'stayed_out_for_too_long',
  OTHER = 'other',
}
