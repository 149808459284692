import { FeatureFlagName } from 'src/models/config'
import { PrinterLabel } from 'src/models/printer_label'
import GlobalStore from 'src/stores/GlobalStore'

// The id for Zebra Technologies Corporation
const VENDOR_ID = 2655

export const zebraPrinterEnabled = () => {
  return (
    !!GlobalStore.getFeatureFlag(FeatureFlagName.ZEBRA_PRINTER_ENABLED) &&
    !!navigator?.usb
  )
}

export const unifiedLabelEnabled = () => {
  return !!GlobalStore.getFeatureFlag(FeatureFlagName.UNIFIED_LABEL_ENABLED)
}

export const continuousLabelEnabled = () => {
  return !!GlobalStore.getFeatureFlag(FeatureFlagName.CONTINUOUS_LABEL_ENABLED)
}

export const printLabel = async (printerLabel: PrinterLabel) => {
  const { error, label = '', code = 'nocode' } = printerLabel
  const response = { error: '' }

  if (error != undefined) {
    response.error = `${code.toUpperCase()}: ${error}`
    return response
  }

  if (!label) {
    response.error = 'Cannot print an empty or undefined label.'
    return response
  }

  const printer = await connectPrinter()
  if (printer) {
    const encoder = new TextEncoder()
    const printData = encoder.encode(label)
    try {
      await printer.transferOut(1, printData)
      return response
    } catch (e) {
      response.error = 'Device disconnected!'
      return response
    }
  } else {
    response.error = 'No printer found'
    return response
  }
}

async function connectPrinter(): Promise<USBDevice | undefined> {
  const devices = await navigator.usb.getDevices()
  let device = devices[0]
  if (devices.length === 0) {
    try {
      // Get permission from the user to use their printer
      device = await navigator.usb.requestDevice({
        filters: [{ vendorId: VENDOR_ID }],
      })
    } catch (e) {
      console.warn(e)
      return
    }
  }
  if (device) {
    await device.open()
    await device.selectConfiguration(1)
    await device.claimInterface(0)
    return device
  } else {
    return
  }
}
