import Amplify from '@aws-amplify/core'

import { AuthConfig } from 'src/models/config'
import env from 'src/utils/environment'

const COGNITO_DOMAIN_MAP = {
  development: 'ordering-services-dev.auth.us-west-2.amazoncognito.com',
  preprod: 'ordering-services-dev.auth.us-west-2.amazoncognito.com',
  staging: 'zipline-os-staging.auth.us-west-2.amazoncognito.com',
  production: 'zipline-os-prod.auth.us-west-2.amazoncognito.com',
}

const COGNITO_DOMAIN = COGNITO_DOMAIN_MAP[env.ENVIRONMENT]

const ALLOWED_OAUTH_POOLS = [
  'us-west-2_ipRxi9NlC', // dev (ordering-services-dev)
  'us-west-2_wo6Tt0tKq', // production (ordering_services_walmart_prod)
  'us-west-2_uHlgpDF5D', // staging (ordering_services_walmart_staging)
]

export function isOauthAllowed(userPoolId: string): boolean {
  return ALLOWED_OAUTH_POOLS.includes(userPoolId)
}

export default function configureAuth(authConfig: AuthConfig): void {
  Amplify.configure({
    Auth: {
      ...authConfig,
      mandatorySignIn: false,
      // Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: COGNITO_DOMAIN,
        redirectSignIn: window.location.origin + '/v3/callback',
        redirectSignOut: window.location.origin + '/v3/callback',
        responseType: 'code',
        scope: [
          'aws.cognito.signin.user.admin',
          'email',
          'openid',
          'profile',
          'phone',
        ],
      },
    },
  })
}
