/*
 * Global client for fulfillment system requests
 */
import axios, { AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

import AuthStore from 'src/stores/AuthStore'
import ViewStore, { ViewId } from 'src/stores/ViewStore'

// TODO: read hostname from env variable

// We export this client so that tests may access it and create mockAdapters.
export const client = axios.create({
  baseURL: `${window.location.protocol}//${window.location.host}/`,
  withCredentials: true,
})

client.interceptors.request.use((config) => {
  config.headers.common['X-CSRFToken'] = Cookies.get('csrftoken')
  return config
})

class Client {
  async get(url: string, config?: AxiosRequestConfig): Promise<any> {
    try {
      const response = await client.get(url, config)
      return response
    } catch (e) {
      // If the user is not logged in, redirect to login page.
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        if (ViewStore.currentView.id !== ViewId.LOGIN) {
          AuthStore.setCurrentUser(undefined)
          ViewStore.showView(ViewId.LOGIN)
        }
        return
      }
      throw e
    }
  }

  post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
    return client.post(url, data, config)
  }

  patch(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
    return client.patch(url, data, config)
  }

  delete(url: string, config?: AxiosRequestConfig): Promise<any> {
    return client.delete(url, config)
  }
}

export default new Client()
