import React from 'react'

import { observer } from 'mobx-react'
import BarcodeReader from 'react-barcode-reader'
import styled, { keyframes } from 'styled-components'

import BarcodeSvg from 'src/components/BarcodeSvg'
import BarcodeScannerStore from 'src/stores/BarcodeScannerStore'

const PopoverContainer = styled.div`
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 100;
  border-radius: 20px;
`

const upAndDown = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(52px);
  }
`

const RedScanLine = styled.div`
  position: absolute;
  width: 129px;
  height: 8px;
  left: 18px;
  top: 30px;
  background: #fff8b5;
  box-shadow:
    0px 0px 4px 2px rgba(231, 60, 62, 0.6),
    inset 0px 0px 4px 2px #e73c3e;
  border-radius: 4px;
  animation: ${upAndDown} 1s infinite alternate ease-in-out;
`

const BarcodeScannerPopover: React.FunctionComponent = observer(() => {
  const { isOn, lastScan } = BarcodeScannerStore

  if (!isOn) return null

  return (
    <PopoverContainer className="shadow bg-primary text-center">
      {lastScan ? (
        <p className="m-0 py-2 px-3 d-flex justify-content-between">
          <code className="text-white">Scan:</code>
          <code className="text-white">
            <strong>{lastScan}</strong>
          </code>
        </p>
      ) : null}
      <div className="position-relative">
        <RedScanLine />
        <BarcodeSvg />
      </div>

      <BarcodeReader
        onScan={(scan: string) => {
          BarcodeScannerStore.onScan(scan)
        }}
      />
    </PopoverContainer>
  )
})

export default BarcodeScannerPopover
