import { NotificationType } from 'src/models/notification'

export enum CreditCheckStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export enum SafetyCheckStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export enum DeliverySiteStatus {
  READY_FOR_OPERATIONS = 'ready_for_operations',
  INACTIVE = 'inactive',
}

export enum DeliveryType {
  HC = 'HD via HC',
  CHW = 'HD via CHW',
  HOME = 'HD to Patient Home',
  HP = 'HD via HP',
}

export enum FacilityStatus {
  CREATED = 0,
  OPERATIONALIZED = 1,
  SUSPENDED_PARTIALLY = 2,
  SUSPENDED_ENTIRELY = 3,
  OBSOLETE = 4,
  DEPRECATED = 5,
}

export const FacilityStatusLabels: Record<FacilityStatus, string> = {
  [FacilityStatus.CREATED]: 'Created',
  [FacilityStatus.OPERATIONALIZED]: 'Operational',
  [FacilityStatus.SUSPENDED_PARTIALLY]: 'Partially Suspended',
  [FacilityStatus.SUSPENDED_ENTIRELY]: 'Entirely Suspended',
  [FacilityStatus.OBSOLETE]: 'Obsolete',
  [FacilityStatus.DEPRECATED]: 'Deprecated',
}

export interface FacilityStatusReason {
  id: number
  status: number
  reason: string
}

export interface DeliverySite {
  id: number
  dropPointId: number
  name: string
  // TODO(SWS-2885): Deprecate this field when fully transitioned to reading from CB.
  estimatedTimeToDelivery?: string
  lastUpdated?: string
  status: DeliverySiteStatus
  isGrounded: boolean
  facilities?: HealthFacility[]
}

export interface HealthFacility extends HealthFacilityPreview {
  address: string
  addressExtended: string
  postcode: string
  country: string
  email: string
  notificationTypes: NotificationType[]
  smsNotificationsEnabled: boolean
  billableEntity?: string | null
  statusReason?: number
}

export interface HealthFacilityGroup {
  id: number
  name: string
  orderableProducts: string[]
}

export interface HealthFacilityPreview {
  id: number
  organizationId?: string
  deliverySites: DeliverySite[]
  directDeliverySiteId?: number
  groups: string[]
  name: string
  notes?: string
  zone?: string
  healthSystemCode?: string
  district: string
  region: string
  creditCheckStatus?: string | null
  timeLastCreditCheck?: string | null
  creditCheckOverrideReason?: string | null
  safetyCheckStatus?: SafetyCheckStatus
  timeLastSafetyCheck?: string
  status: FacilityStatus
}

export interface HealthFacilityCreditStatus {
  id: number
  name?: string
  creditCheckStatus: string | null
  timeLastCreditCheck?: string | null
}

export interface HealthFacilityLocation {
  id: number
  name: string
  district?: string
  region?: string
}

export interface OrderStatusHealthFacility extends HealthFacilityLocation {
  id: number
  email: string
  creditCheckStatus?: string | null
  timeLastCreditCheck?: string | null
  smsNotificationsEnabled: boolean
}

export interface FacilityStock {
  id: number // facility id
  productId: string
  stockCount: number
}

export enum SiteType {
  FACILITY = 'facility',
  DELIVERY_SITE = 'delivery_site',
}
