import isUndefined from 'lodash/isUndefined'
import keyBy from 'lodash/keyBy'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import {
  AuthConfig,
  FeatureFlag,
  FeatureFlagName,
  UiConfig,
} from 'src/models/config'
import { NestHardwareConfig } from 'src/models/nest'
import { Attribute } from 'src/models/product'
import { HealthFacilityGroup } from 'src/models/sites'
import { Supplier } from 'src/models/supplier'
import { UnitStatus } from 'src/models/unit'
import * as AttributeService from 'src/services/AttributeService'
import * as AuthenticationService from 'src/services/AuthenticationService'
import * as ConfigService from 'src/services/ConfigService'
import * as HealthFacilityService from 'src/services/HealthFacilityService'
import * as NestService from 'src/services/NestService'
import * as SupplierService from 'src/services/SupplierService'
import * as UnitService from 'src/services/UnitService'
import configureAuth from 'src/utils/Auth/configureAuth'

class GlobalStore {
  @observable appReady = false
  @observable packageAudioEnabled = false
  @observable orderAudioEnabled = false
  @observable serviceWorkerUpdate: (() => void) | null = null
  @observable uiConfig?: UiConfig
  @observable authConfig?: AuthConfig
  @observable attributes?: Attribute[]
  @observable healthFacilityGroups?: HealthFacilityGroup[]
  @observable suppliers?: Supplier[]
  @observable unitStatuses?: UnitStatus[]
  @observable unitDerivedStatuses?: UnitStatus[]
  @observable nestHardwareConfig?: NestHardwareConfig

  constructor() {
    makeObservable(this)
  }

  @computed get unitStatusesByName(): Record<string, UnitStatus> | undefined {
    if (isUndefined(this.unitStatuses)) {
      return this.unitStatuses
    }

    return keyBy(this.unitStatuses, 'name')
  }

  @computed get unitDerivedStatusesByName():
    | Record<string, UnitStatus>
    | undefined {
    if (isUndefined(this.unitDerivedStatuses)) {
      return this.unitDerivedStatuses
    }

    return keyBy(this.unitDerivedStatuses, 'name')
  }

  @computed get nestKey(): string {
    const nestKey = this.uiConfig!.nestKey
    // Ensure that RW1 and RW2 are corrected to the appropriate values.
    return (
      {
        rw1: 'nest1',
        rw2: 'nest2',
      }[nestKey] ?? nestKey
    )
  }

  getFeatureFlag(name: FeatureFlagName): FeatureFlag | undefined {
    return this.uiConfig?.featureFlags?.[name]
  }

  @action.bound async configureAuth(): Promise<AuthConfig> {
    const config = await AuthenticationService.getAuthConfig()
    runInAction(() => {
      configureAuth(config)
      this.authConfig = config
    })
    return config
  }

  @action.bound initApp(): Promise<void> {
    // Runs all actions required for startup
    // Sets GlobalStore.appReady to true when complete
    const initPromise = Promise.all([this.getUiConfig()]).then(() => {
      runInAction(() => (this.appReady = true))
    })

    // Fetches other globals that don't block startup
    // TODO(ivan.wang): Consider doing this lazily for less important globals.
    this.getAttributes()
    this.getHealthFacilityGroups()
    this.getSuppliers()
    this.getUnitStatuses()
    this.getUnitDerivedStatuses()
    this.getNestHardwareConfig()

    return initPromise
  }

  @action async getUiConfig(): Promise<UiConfig> {
    const config = await ConfigService.getUiConfig()

    runInAction(() => {
      this.uiConfig = config
    })

    return config
  }

  @action.bound setServiceWorkerUpdate(callback: (() => void) | null) {
    this.serviceWorkerUpdate = callback
  }

  @action.bound async getAttributes(): Promise<Attribute[]> {
    const attributes = await AttributeService.getAttributes()
    runInAction(() => (this.attributes = attributes))
    return attributes
  }

  @action.bound async getHealthFacilityGroups(): Promise<
    HealthFacilityGroup[]
  > {
    const healthFacilityGroups =
      await HealthFacilityService.getHealthFacilityGroups()
    runInAction(() => (this.healthFacilityGroups = healthFacilityGroups))
    return healthFacilityGroups
  }

  @action.bound async getSuppliers(): Promise<Supplier[]> {
    const suppliers = await SupplierService.getSuppliers()
    runInAction(() => (this.suppliers = suppliers))
    return suppliers
  }

  @action.bound async getUnitStatuses(): Promise<UnitStatus[]> {
    const statuses = await UnitService.getUnitStatuses()
    runInAction(() => (this.unitStatuses = statuses))
    return statuses
  }

  @action.bound async getUnitDerivedStatuses(): Promise<UnitStatus[]> {
    const statuses = await UnitService.getUnitDerivedStatuses()
    runInAction(() => (this.unitDerivedStatuses = statuses))
    return statuses
  }

  @action.bound async getNestHardwareConfig(): Promise<NestHardwareConfig> {
    const nestHardwareConfig = await NestService.getNestHWConfig()
    runInAction(() => (this.nestHardwareConfig = nestHardwareConfig))
    return nestHardwareConfig
  }

  @action.bound togglePackageAudioEnabled() {
    this.packageAudioEnabled = !this.packageAudioEnabled
  }

  @action.bound toggleOrderAudioEnabled() {
    this.orderAudioEnabled = !this.orderAudioEnabled
  }
}

export default new GlobalStore()
